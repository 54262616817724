import { create } from 'zustand'
import { IChat, IQuestion, ITag, ISource } from '../../models'

interface MainState {
    chats: Array<IChat>,
    setChats: (chats: Array<IChat>) => void,
    savedChats: Array<IChat>,
    setSavedChats: (chats: Array<IChat>) => void,
    addChat: (chat: IChat) => void,
    currentChat: IChat | null,
    setCurrentChat: (chat: IChat | null) => void,
    currentQuestions: Array<IQuestion>,
    setCurrentQuestions: (questions: Array<IQuestion>) => void,
    shouldType: boolean,
    setShouldType: (shouldType: boolean) => void,
    currentSources: Array<ISource>,
    setCurrentSources: (sources: Array<ISource>) => void,
    currentTags: Array<ITag>,
    setCurrentTags: (tags: Array<ITag>) => void,
    currentTemplate: string | null,
    setCurrentTemplate: (template: string | null) => void,
    currentTemplateParams: string | null,
    setCurrentTemplateParams: (templateParams: string | null) => void,
    showNewChat: boolean
    setShowNewChat: (showNewChat: boolean) => void,
    showScoreModal: boolean,
    setShowScoreModal: (showScoreModal: boolean) => void,
}

export const useMainStore = create<MainState>()((set) => ({
    chats: new Array<IChat>(),
    setChats: (chats) => set(() => ({ chats: [...chats] })),
    savedChats: new Array<IChat>(),
    setSavedChats: (chats) => set(() => ({ chats: [...chats] })),
    addChat: (chat) => set((state) => ({ chats: [chat, ...state.chats] })),
    currentChat: null,
    setCurrentChat: (chat) => set(() => ({ currentChat: chat, shouldType: false })),
    currentQuestions: new Array<IQuestion>(),
    setCurrentQuestions: (questions) => set(() => ({ currentQuestions: [...questions] })),
    shouldType: false,
    setShouldType: (shouldType) => set(() => ({ shouldType: shouldType })),
    currentSources: [],
    setCurrentSources: (sources) => set(() => ({ currentSources: sources })),
    currentTags: [],
    setCurrentTags: (tags) => set(() => ({ currentTags: tags })),
    currentTemplate: null,
    setCurrentTemplate: (template) => set(() => ({ currentTemplate: template })),
    currentTemplateParams: null,
    setCurrentTemplateParams: (templateParams) => set(() => ({ currentTemplateParams: templateParams })),
    showNewChat: false,
    setShowNewChat: (showNewChat) => set(() => ({ showNewChat: showNewChat })),
    showScoreModal: false,
    setShowScoreModal: (showScoreModal) => set(() => ({ showScoreModal: showScoreModal })),
}))