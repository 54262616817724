import { IChat } from "../../models"
import { connectionsClient } from "../../utils/ConnectionsClient"
import { ChatTypes } from "./chat.types"

class Chat {

    async list(search?: string, sources?: Array<number>, saved?: boolean, ordering?: string): Promise<Array<IChat>> {
        const response = await connectionsClient.client.get("chats", {
            params: { limit: 999, search: search, sources: sources && sources.length > 0 ? sources : sources ? "" : undefined, ordering: ordering ?? "-created_at", saved: saved },
            paramsSerializer: {
                indexes: null,
            }
        });
        return response.data;
    }

    async getSharedOne(shareId: string): Promise<IChat> {
        const response = await connectionsClient.client.get(`shared-chats/${shareId}/`);
        return response.data;
    }

    async create({ name }: ChatTypes.Create): Promise<IChat> {
        const response = await connectionsClient.client.post("chats", { name, questions: [] });
        return response.data;
    }

    async update({ chatId, rate }: ChatTypes.Update): Promise<IChat> {
        const response = await connectionsClient.client.patch(`chats/${chatId}`, { rate });
        return response.data;
    }

    async updateSavedChats({ chatId }: ChatTypes.Update): Promise<IChat> {
        const response = await connectionsClient.client.patch(`saved-chats/${chatId}`);
        return response.data;
    }

    async delete(chat: IChat): Promise<{ res: string }> {
        const response = await connectionsClient.client.delete(`chats/${chat.id}`);
        return response.data;
    }
}

export const chat = new Chat();