import { useContext, useState } from "react";
import { Drawer, Divider, Box, IconButton } from "@mui/material";
import {
	NewChat,
	ChatList,
	User,
	BasicModal,
	UploadSourcesButton,
	UploadSources,
	NewChatOnboarding,
} from "../../components";
import { PropTypes } from "./SideBar.types";
import { HiMenuAlt3 as MenuIcon } from "react-icons/hi";
import { UserContext } from "../../contexts/user";
import { useMainStore } from "../../stores/main";

const drawerWidth = 280;

export function SideBar({ mobileOpen, handleDrawerToggle }: PropTypes) {
	const { user } = useContext(UserContext);

	const showNewChat = useMainStore((state) => state.showNewChat);
	const setShowNewChat = useMainStore((state) => state.setShowNewChat);

	const [showModal, setShowModal] = useState(false);
	const handleOpen = () => setShowModal(true);
	const handleClose = () => setShowModal(false);

	const handleOpenNewChat = () => setShowNewChat(true);
	const handleCloseNewChat = () => setShowNewChat(false);
	const drawer = (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "space-between",
				padding: 2,
				textAlign: "center",
				height: "100%",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					width: "100%",
				}}
			>
				<IconButton
					aria-label="menu"
					sx={{ alignSelf: "flex-end", marginBottom: 2, fontSize: 18 }}
					onClick={() => handleDrawerToggle()}
				>
					<MenuIcon />
				</IconButton>
				<NewChat handleOpenNewChat={handleOpenNewChat} />
				<Divider sx={{ opacity: 0.3, margin: "14px 8px" }} />
				<ChatList />
			</Box>

			<Box
				sx={{
					width: "100%",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Divider sx={{ opacity: 0.3, margin: 2 }} />
				<UploadSourcesButton handleOpen={handleOpen} />
				<Divider sx={{ opacity: 0.3, margin: 2 }} />
				<User name={user?.username ?? ""} />
			</Box>
		</Box>
	);

	const successCallback = () => {
		setShowModal(false);
	};

	return (
		<>
			<Box
				component="nav"
				sx={{
					width: { sm: mobileOpen ? drawerWidth : 0 },
					flexShrink: { sm: 0 },
				}}
			>
				<Drawer
					variant="temporary"
					open={mobileOpen}
					onClose={handleDrawerToggle}
					ModalProps={{
						keepMounted: true,
					}}
					PaperProps={{
						sx: {
							backgroundColor: "#fff",
							border: "none",
							borderRight: "1px solid #ececec",
						},
					}}
					sx={{
						display: { xs: "block", sm: "none" },
						"& .MuiDrawer-paper": {
							boxSizing: "border-box",
							width: drawerWidth,
						},
					}}
				>
					{drawer}
				</Drawer>
				<Drawer
					variant="persistent"
					PaperProps={{
						sx: {
							backgroundColor: "#fff",
							border: "none",
							borderRight: "1px solid #ececec",
						},
					}}
					sx={{
						display: { xs: "none", sm: "block" },
						"& .MuiDrawer-paper": {
							boxSizing: "border-box",
							width: drawerWidth,
						},
					}}
					open={mobileOpen}
				>
					{drawer}
				</Drawer>
			</Box>
			<BasicModal
				width={600}
				showModal={showModal}
				handleClose={handleClose}
				children={<UploadSources successCallback={successCallback} />}
			/>

			<BasicModal
				width={600}
				showModal={showNewChat}
				handleClose={handleCloseNewChat}
				children={<NewChatOnboarding handleClose={handleCloseNewChat} />}
			/>
		</>
	);
}
