import {
	Typography,
	Box,
	TextField,
	Button,
	FormControl,
	Tabs,
	Tab,
	Grid,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { PropTypes } from "./SelectTemplate.types";
import { useMainStore } from "../../stores/main";
import { useContext, useMemo, useState } from "react";
import { ITemplate } from "../../models";
import { SuggestedTemplate } from "../SuggestedTemplate";
import { UserContext } from "../../contexts/user";

const useStyles = makeStyles({
	inputLabel: {
		textTransform: "uppercase",
		fontSize: 10,
		marginTop: 16,
		marginBottom: 4,
		fontWeight: 700,
		color: "#686868",
	},
});

export function SelectTemplate(props: PropTypes) {
	const classes = useStyles();
	const { user } = useContext(UserContext);
	const currentTemplate = useMainStore((state) => state.currentTemplate);
	const currentTemplateParams = useMainStore(
		(state) => state.currentTemplateParams
	);
	const setCurrentTemplate = useMainStore((state) => state.setCurrentTemplate);
	const setCurrentTemplateParams = useMainStore(
		(state) => state.setCurrentTemplateParams
	);
	const [template, setTemplate] = useState(currentTemplate ?? "");
	const [templateParams, setTemplateParams] = useState(
		currentTemplateParams ?? ""
	);

	const [tabValue, setTabValue] = useState(0);

	const templates = useMemo(() => {
		const templates = [
			{
				id: 1,
				name: "Smart Brevity",
				description: `You are a helpful assistant for people working on climate and sustainability, and should always speak in a friendly but professional manner for all responses.

You must obey ALL of the following rules:
- If you don't know the answer of any of the questions you will be asked, don't try to make up an answer and just LITERALLY return "Sorry, I don't know the answer" as the answer
- Always use the "Smart Brevity" approach to respond

Then given a specific context, please give a good answer to the question, covering the already given required advices in general.
----
{context}
----`,
			},
			{
				id: 2,
				name: "Long Answers",
				description: `You are a helpful assistant for people working on climate and sustainability, and should always speak in a friendly but professional manner for all responses.

You must obey ALL of the following rules:
- If you don't know the answer of any of the questions you will be asked, don't try to make up an answer and just LITERALLY return "Sorry, I don't know the answer" as the answer
- Always write in long length paragraphs

Then given a specific context, please give a good answer to the question, covering the already given required advices in general.
----
{context}
----`,
			},
		];

		return templates;
	}, []);

	const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
		setTabValue(newValue);
	};

	const [selectedTemplate, setSelectedTemplate] = useState<ITemplate | null>(
		templates.find((template) => template.description === currentTemplate) ??
			null
	);

	function a11yProps(index: number) {
		return {
			id: `simple-tab-${index}`,
			"aria-controls": `simple-tabpanel-${index}`,
		};
	}

	const onSubmit = () => {
		setCurrentTemplate(selectedTemplate?.description ?? template ?? null);
		setCurrentTemplateParams(
			selectedTemplate?.description && templateParams ? templateParams : null
		);
		if (props.successCallback) {
			props.successCallback();
		}
	};

	return (
		<Box sx={{ width: "100%" }}>
			<Typography
				variant="h5"
				sx={{ fontWeight: 700, textAlign: "center", marginBottom: 2 }}
			>
				Select Template
			</Typography>
			<Box
				sx={{
					borderBottom: 1,
					borderColor: "divider",
					display: "flex",
					justifyContent: "center",
				}}
			></Box>
			<Box sx={{ display: "flex", justifyContent: "center" }}>
				<Tabs
					TabIndicatorProps={{
						style: {
							display: "none",
						},
					}}
					sx={{}}
					value={tabValue}
					onChange={handleTabChange}
					aria-label="chats tabs "
				>
					<Tab label="EXISTING" {...a11yProps(0)} />
					{user && user.is_staff && <Tab label="NEW" {...a11yProps(1)} />}
				</Tabs>
			</Box>
			<Box
				sx={{
					borderBottom: 1,
					borderColor: "divider",
					display: "flex",
					justifyContent: "center",
					marginBottom: "40px",
				}}
			></Box>
			{tabValue === 0 && (
				<Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
					<Grid
						container
						spacing={2}
						padding={2}
						sx={{ maxHeight: "calc(100vh - 500px)", overflow: "auto" }}
					>
						{templates.map((predefinedTemplate, index) => (
							<Grid key={index} item xs={12} sm={6} md={6}>
								<SuggestedTemplate
									template={predefinedTemplate}
									callback={(selected) => {
										setSelectedTemplate(selected ? null : predefinedTemplate);
										setTemplate(selected ? "" : predefinedTemplate.description);
									}}
									selectedTemplate={selectedTemplate || undefined}
									disabled={index === 1}
								/>
							</Grid>
						))}
					</Grid>
					<Button
						variant="contained"
						sx={{ marginTop: 4, maxWidth: 300, alignSelf: "end" }}
						onClick={onSubmit}
					>
						Select
					</Button>
				</Box>
			)}
			{tabValue === 1 && (
				<FormControl sx={{ width: "100%" }}>
					<span className={classes.inputLabel}>Template</span>
					<TextField
						InputLabelProps={{ shrink: false }}
						fullWidth
						label=""
						id="title"
						multiline
						rows={3}
						placeholder=""
						value={template}
						onChange={(e) => {
							setTemplate(e.target.value);
						}}
					/>
					<span className={classes.inputLabel}>Template params</span>
					<TextField
						InputLabelProps={{ shrink: false }}
						fullWidth
						label=""
						multiline
						rows={3}
						id="description"
						placeholder=""
						value={templateParams}
						onChange={(e) => {
							setTemplateParams(e.target.value);
						}}
					/>

					<Button
						variant="contained"
						sx={{
							marginTop: 2,
							maxWidth: 300,
							alignSelf: "end",
							marginLeft: "auto",
						}}
						onClick={onSubmit}
					>
						Save
					</Button>
				</FormControl>
			)}
		</Box>
	);
}
