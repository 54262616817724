import * as React from "react";
import { Typography, Box, Button } from "@mui/material";
import { PropTypes } from "./DeleteConfirmation.types";

export function DeleteConfirmation(props: PropTypes) {
	const { title, handleDeleteModalClose, deleteItem, itemType } = props;

	return (
		<Box sx={{ width: "100%" }}>
			<Typography
				variant="h5"
				sx={{ fontWeight: 700, textAlign: "center", marginBottom: 2 }}
			>
				Do you want to delete the {itemType}?
			</Typography>
			<Typography variant="body2" sx={{ textAlign: "center", marginBottom: 2 }}>
				The {itemType} <span style={{ fontStyle: "italic" }}>{title}</span> will
				be deleted
			</Typography>
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
				}}
			>
				<Button
					variant="outlined"
					sx={{ marginTop: 2, maxWidth: 300, alignSelf: "end", marginRight: 2 }}
					onClick={() => {
						handleDeleteModalClose();
					}}
				>
					Cancel
				</Button>
				<Button
					variant="contained"
					sx={{
						marginTop: 2,
						maxWidth: 300,
						alignSelf: "end",
						backgroundColor: "#e25757",

						"&:hover": {
							backgroundColor: "#b44545",
						},
					}}
					onClick={() => {
						deleteItem();
					}}
				>
					Delete
				</Button>
			</Box>
		</Box>
	);
}
