import { useEffect, useState } from "react";
import { Box, Button, IconButton, InputAdornment } from "@mui/material";
import { BiSend as SendIcon } from "react-icons/bi";
import { TbTemplate as TemplateIcon } from "react-icons/tb";
import { PropTypes } from "./BottomBar.types";
import { MdOutlinePolicy as PolicyIcon } from "react-icons/md";
import { PiTagSimpleBold as TagIcon } from "react-icons/pi";
import {
	question as questionActions,
	source as sourceActions,
	tag as tagActions,
} from "../../controllers";
import { useMainStore } from "../../stores/main";
import { SnackbarKey, useSnackbar } from "notistack";
import { useQuery, useMutation } from "react-query";
import { BasicModal } from "../BasicModal";
import { MultiItemSelector } from "../MultiItemSelector";
import { SelectTemplate } from "../SelectTemplate";
import { ISource, ITag } from "../../models";
import { MentionsInput, Mention } from "react-mentions";

export function BottomBar(_: PropTypes) {
	const [message, setMessage] = useState("");
	const chats = useMainStore((state) => state.chats);
	const setChats = useMainStore((state) => state.setChats);
	const currentChat = useMainStore((state) => state.currentChat);
	const currentQuestions = useMainStore((state) => state.currentQuestions);
	const setCurrentQuestions = useMainStore(
		(state) => state.setCurrentQuestions
	);
	const setShouldType = useMainStore((state) => state.setShouldType);
	const currentSources = useMainStore((state) => state.currentSources);
	const currentTags = useMainStore((state) => state.currentTags);
	const setCurrentSources = useMainStore((state) => state.setCurrentSources);
	const setCurrentTags = useMainStore((state) => state.setCurrentTags);
	const currentTemplate = useMainStore((state) => state.currentTemplate);
	const currentTemplateParams = useMainStore(
		(state) => state.currentTemplateParams
	);

	const [sourcesFilter, setSourcesFilter] = useState("");
	const [tagsFilter, setTagsFilter] = useState("");
	const [sources, setSources] = useState<Array<ISource>>(Array<ISource>());
	const [tags, setTags] = useState<Array<ITag>>(Array<ITag>());

	const [showSourceModal, setShowSourceModal] = useState(false);
	const handleSourceOpen = () => setShowSourceModal(true);
	const handleSourceClose = () => {
		setShowSourceModal(false);
		setSourcesFilter("");
	};

	const [showTemplateModal, setShowTemplateModal] = useState(false);
	const handleTemplateOpen = () => setShowTemplateModal(true);
	const handleTemplateClose = () => setShowTemplateModal(false);

	const [showTagModal, setShowTagModal] = useState(false);
	const handleTagOpen = () => setShowTagModal(true);
	const handleTagClose = () => {
		setShowTagModal(false);
		setTagsFilter("");
	};

	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const { refetch: refetchSources } = useQuery(
		"sources",
		() => sourceActions.list(sourcesFilter),
		{
			enabled: true,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				console.log(res);
				setSources(res);
			},
			onError: (error: any) => {
				console.log(error);
				const key: SnackbarKey = enqueueSnackbar("Something went wrong", {
					variant: "error",
					SnackbarProps: {
						onClick: () => closeSnackbar(key),
					},
				});
			},
		}
	);

	const { refetch: refetchTags } = useQuery(
		"tags",
		() => tagActions.list(tagsFilter),
		{
			enabled: true,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				console.log(res);
				setTags(res);
			},
			onError: (error: any) => {
				console.log(error);
				const key: SnackbarKey = enqueueSnackbar("Something went wrong", {
					variant: "error",
					SnackbarProps: {
						onClick: () => closeSnackbar(key),
					},
				});
			},
		}
	);

	const { mutate } = useMutation(
		"questions",
		() =>
			questionActions.create({
				message: message,
				chatId: currentChat!.id,
				system_template: currentTemplate,
				system_template_params: currentTemplateParams,
				answers: [],
				sources: currentSources.map((source) => source.id),
				tags: currentTags.map((tag) => tag.id),
			}),
		{
			onSuccess: (res) => {
				setShouldType(true);
				let updatedQuestions = [...currentQuestions];
				updatedQuestions[updatedQuestions.length] = res;
				setCurrentQuestions([...updatedQuestions]);

				let chatIndex = chats.findIndex((chat) => chat.id === currentChat?.id);
				if (chatIndex > -1) {
					chats[chatIndex].name = message;
					setChats(chats);
				}
			},
			onError: (error: any) => {
				console.log(error);
				const key: SnackbarKey = enqueueSnackbar("Something went wrong", {
					variant: "error",
					SnackbarProps: {
						onClick: () => closeSnackbar(key),
					},
				});
			},
		}
	);

	const createMessage = () => {
		if (message) {
			setCurrentQuestions([
				...currentQuestions,
				{
					id: -Math.floor(Math.random() * 10000),
					message,
					system_template: currentTemplate,
					system_template_params: currentTemplateParams,
					answers: [],
					sources: currentSources,
					tags: currentTags,
					created_at: new Date().toISOString(),
					updated_at: new Date().toISOString(),
				},
			]);
			setMessage("");
			mutate();
		}
	};

	const sendMessage = () => {
		createMessage();
	};

	const onKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === "Enter" && !e.shiftKey && !e.ctrlKey) {
			e.preventDefault();
			createMessage();
		}
	};

	useEffect(() => {
		if (showSourceModal) {
			refetchSources();
		}
	}, [refetchSources, showSourceModal]);

	useEffect(() => {
		refetchSources();
	}, [refetchSources, sourcesFilter]);

	useEffect(() => {
		refetchTags();
	}, [refetchTags, tagsFilter]);

	return (
		<>
			<Box
				sx={{
					padding: 2,
				}}
			>
				<Button
					variant="text"
					sx={{ marginBottom: 1, justifyContent: "flex-start" }}
					onClick={() => {
						handleSourceOpen();
					}}
				>
					<PolicyIcon style={{ marginRight: 4 }} />
					Select Source{" "}
					{currentSources.length > 0 && (
						<sup
							style={{
								width: 14,
								height: 14,
								borderRadius: 7,
								background: "#00b093",
								color: "white",
								textAlign: "center",
								marginTop: -10,
								fontSize: 10,
								lineHeight: 1.3,
							}}
						>
							{currentSources.length}
						</sup>
					)}
				</Button>
				<Button
					variant="text"
					sx={{ marginBottom: 1, justifyContent: "flex-start" }}
					onClick={() => {
						handleTagOpen();
					}}
				>
					<TagIcon style={{ marginRight: 4 }} />
					Select Tags
					{currentTags.length > 0 && (
						<sup
							style={{
								width: 14,
								height: 14,
								borderRadius: 7,
								background: "#00b093",
								color: "white",
								textAlign: "center",
								marginTop: -10,
								fontSize: 10,
								lineHeight: 1.3,
							}}
						>
							{currentTags.length}
						</sup>
					)}
				</Button>
				<Button
					variant="text"
					sx={{ marginBottom: 1, justifyContent: "flex-start" }}
					onClick={() => {
						handleTemplateOpen();
					}}
				>
					<TemplateIcon style={{ marginRight: 4 }} />
					Select Template
					{currentTemplate && (
						<sup
							style={{
								width: 14,
								height: 14,
								borderRadius: 7,
								background: "#00b093",
								color: "white",
								textAlign: "center",
								marginTop: -10,
								fontSize: 10,
								lineHeight: 1.3,
							}}
						>
							!
						</sup>
					)}
				</Button>
				<Box
					sx={{
						background: "rgb(250, 250, 250)",
						textAlign: "left",
						display: "flex",
						justifyContent: "space-between",
						marginBottom: 2,
					}}
				>
					<MentionsInput
						value={message}
						className="comments-textarea"
						onChange={(_, __, newPlainTextValue) => {
							setMessage(newPlainTextValue);
						}}
						allowSpaceInQuery
						style={{
							width: "100%",
							border: "none!important",
							outline: "none!important",
						}}
						placeholder={
							"Send a question" +
							(currentSources.length > 0
								? ' - Tip: input "@" in order to refer to a specific document.'
								: "")
						}
						autoFocus
						disabled={!currentChat}
						id="question"
						onKeyDown={onKeyPress}
						forceSuggestionsAboveCursor
					>
						<Mention
							trigger="@"
							appendSpaceOnAdd
							data={currentSources.map((source) => ({
								id: source.id,
								display: `"${source.name}"`,
							}))}
							renderSuggestion={(suggestion) => {
								return (
									<Box sx={{ padding: 2 }}>
										{suggestion.display!.length > 64
											? suggestion.display!.slice(0, 64) + "..."
											: suggestion.display}
									</Box>
								);
							}}
						/>
					</MentionsInput>
					<Box
						sx={{
							display: "flex",
							marginTop: "28px",
							justifyContent: "flex-start",
							height: "100%",
						}}
					>
						<InputAdornment position="end">
							<IconButton
								disabled={!currentChat}
								aria-label="send message"
								edge="end"
								onClick={sendMessage}
								sx={{ marginRight: "4px" }}
							>
								<SendIcon />
							</IconButton>
						</InputAdornment>
					</Box>
				</Box>
			</Box>
			<BasicModal
				width={600}
				showModal={showSourceModal}
				handleClose={handleSourceClose}
				children={
					<MultiItemSelector
						title="Select Sources"
						placeholderAutocomplete="Search Source"
						buttonTitle="Add source"
						listTitle="Sources Selected"
						emptyState="No sources applied yet"
						setFilter={setSourcesFilter}
						elements={sources}
						selectedElements={currentSources}
						setSelectedElements={(elements) =>
							setCurrentSources(elements as Array<ISource>)
						}
					/>
				}
			/>
			<BasicModal
				width={600}
				showModal={showTemplateModal}
				handleClose={handleTemplateClose}
				children={
					<SelectTemplate
						successCallback={() => {
							setShowTemplateModal(false);
						}}
					/>
				}
			/>
			<BasicModal
				width={600}
				showModal={showTagModal}
				handleClose={handleTagClose}
				children={
					<MultiItemSelector
						title="Select Tags"
						placeholderAutocomplete="Select Tag"
						buttonTitle="Add tag"
						listTitle="Tags Selected"
						emptyState="No tags applied yet"
						setFilter={setTagsFilter}
						elements={tags}
						selectedElements={currentTags}
						setSelectedElements={(elements) =>
							setCurrentTags(elements as Array<ITag>)
						}
					/>
				}
			/>
		</>
	);
}
