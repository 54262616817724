import { IQuestion } from "../../models"
import { connectionsClient } from "../../utils/ConnectionsClient"
import { QuestionTypes } from "./question.types"

class Question {

    async list(chatId: number): Promise<Array<IQuestion>> {
        const response = await connectionsClient.client.get(`chats/${chatId}/questions`);
        return response.data;
    }

    async create({ chatId, ...params }: QuestionTypes.Create): Promise<IQuestion> {
        const response = await connectionsClient.client.post(`chats/${chatId}/questions`, params);
        return response.data;
    }

    async delete({ chatId, questionId }: QuestionTypes.Delete): Promise<IQuestion> {
        const response = await connectionsClient.client.delete(`chats/${chatId}/questions/${questionId}`);
        return response.data;
    }
}

export const question = new Question();