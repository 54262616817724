import { Typography, Box, Button } from "@mui/material";
import { PropTypes } from "./Onboarding.types";

export function Onboarding(props: PropTypes) {
	return (
		<Box sx={{ width: "100%" }}>
			<Typography
				variant="h5"
				sx={{ fontWeight: 700, textAlign: "center", marginBottom: 2 }}
			>
				Policy AI Tool
			</Typography>
			<Box
				sx={{
					borderBottom: 1,
					borderColor: "divider",
					display: "flex",
					justifyContent: "center",
					marginBottom: 4,
				}}
			></Box>
			<Typography variant="body2" sx={{ marginBottom: 2 }}>
				You are apart of a small team testing this AI Tool. Thank you! We are
				looking for your help in two areas:
				<ol>
					<li style={{ marginBottom: 20 }}>
						1. There are going to be errors which is why we are testing the
						tool. Please take screenshots of any errors and write one to two
						sentences about what led to the error.
					</li>
					<li>
						2. Ideas on how your business unit can leverage the capabilities of
						this technology to bring value to your team and clients. We highly
						encourage you to think outside of the box.
					</li>
				</ol>
				You have already been added to the "AI Policy Tool Testers". To make
				this process as easy as possible for you all, please send any errors to
				the "Send Errors Here" channel and any ideas to "Send Ideas Here"
				channel.
				<br />
				<br />
				We will take what you send and turn it into action!
			</Typography>
			<Box sx={{ display: "flex", justifyContent: "center" }}>
				<Button variant="contained" onClick={props.handleClose}>
					Got it
				</Button>
			</Box>
		</Box>
	);
}
