import {
	Card,
	CardActions,
	CardContent,
	Typography,
	Button,
} from "@mui/material";
import { PropTypes } from "./AnswerSource.types";

export function AnswerSource({ answerSource }: PropTypes) {
	const goToLink = () => {
		window.open(answerSource.source.url, "_blank");
	};

	return (
		<Card
			sx={{ height: "100%", boxShadow: "none", border: "1px solid #eaeaea" }}>
			<CardContent>
				<Typography
					sx={{ fontSize: 10, textTransform: "uppercase" }}
					color="text.secondary"
					gutterBottom>
					{answerSource.source.source_type}
				</Typography>
				<Typography
					variant="body2"
					component="div"
					sx={{
						display: "-webkit-box",
						WebkitBoxOrient: "vertical",
						WebkitLineClamp: 3,
						overflow: "hidden",
						textOverflow: "ellipsis",
						fontSize: 12,
						fontStyle: "italic",
						margin: 2,
					}}>
					{answerSource.content}
				</Typography>
				<Typography variant="body2" fontSize={12}>
					Found in{" "}
					<span style={{ fontWeight: 500 }}>{answerSource.source.name}</span>
					{answerSource.page && answerSource.page !== 0 ? (
						<span style={{ color: "#aaa" }}> - Page {answerSource.page}</span>
					) : null}
				</Typography>
			</CardContent>
			<CardActions sx={{ justifyContent: "flex-end" }}>
				<Button onClick={goToLink} size="small">
					Check it
				</Button>
			</CardActions>
		</Card>
	);
}
