import { Box, Typography } from "@mui/material";
import Avatar from "react-avatar";
import { PropTypes } from "./User.types";
import { MoreButton } from "../MoreButton";
import { connectionsClient } from "../../utils/ConnectionsClient";
import { useContext } from "react";
import { UserContext } from "../../contexts/user";

export function User(props: PropTypes) {
	const { name } = props;
	const { setUser } = useContext(UserContext);

	const logout = () => {
		connectionsClient.updateTokens({ access: null, refresh: null });
		setUser(null);
	};

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-between",
				alignItems: "center",
			}}>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "left",
					alignItems: "center",
				}}>
				<Avatar
					name={name}
					size="34"
					round="6px"
					style={{ fontSize: "18px", marginRight: "12px" }}></Avatar>
				<Typography
					variant="body2"
					gutterBottom
					sx={{
						overflow: "hidden",
						textOverflow: "ellipsis",
						display: "-webkit-box",
						"-webkit-box-orient": "vertical",
						"-webkit-line-clamp": "1",
						marginBottom: 0,
						textTransform: "capitalize",
					}}>
					{name}
				</Typography>
			</Box>
			<MoreButton
				menuOptions={[
					{
						label: "Logout",
						callback: logout,
					},
				]}
			/>
		</Box>
	);
}
