import { Grid } from "@mui/material";
import { RecommendationItem } from "../../components";
import { motion } from "framer-motion";
import { useMemo } from "react";
import { useMainStore } from "../../stores/main";

const container = {
	hidden: { opacity: 1, scale: 0 },
	visible: {
		opacity: 1,
		scale: 1,
		transition: {
			delayChildren: 0.3,
			staggerChildren: 0.2,
		},
	},
};

const item = {
	hidden: { y: 20, opacity: 0 },
	visible: {
		y: 0,
		opacity: 1,
	},
};

export function Recommendations() {
	const setShowNewChat = useMainStore((state) => state.setShowNewChat);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const handleOpenNewChat = () => setShowNewChat(true);

	const chats = useMainStore((state) => state.chats);
	const setCurrentChat = useMainStore((state) => state.setCurrentChat);

	const recommendations = useMemo(() => {
		const createChat = () => {
			handleOpenNewChat();
		};

		const recomendations = [];

		if (chats.length > 0) {
			recomendations.push({
				title: "Continue your last chat",
				subtitle: "your last chat",
				callback: () => {
					setCurrentChat(chats[0]);
				},
			});
		}
		recomendations.push({
			title: "Start a new chat",
			subtitle: "Go for it!",
			callback: () => {
				createChat();
			},
		});
		return recomendations;
	}, [chats, handleOpenNewChat, setCurrentChat]);

	return (
		<motion.ul
			variants={container}
			style={{ paddingLeft: 0 }}
			initial="hidden"
			animate="visible"
		>
			<Grid container spacing={2} padding={2}>
				{recommendations.map((recommendation, index) => (
					<Grid
						key={index}
						item
						xs={12}
						sm={recommendations.length === 1 ? 12 : 6}
						md={recommendations.length === 1 ? 12 : 6}
					>
						<motion.li
							key={1}
							variants={item}
							style={{ display: "flex", height: "100%" }}
						>
							<RecommendationItem
								title={recommendation.title}
								subtitle={recommendation.subtitle}
								callback={recommendation.callback}
							/>
						</motion.li>
					</Grid>
				))}
			</Grid>
		</motion.ul>
	);
}
