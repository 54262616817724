import { useContext } from "react";
import { AppBar, Toolbar, IconButton, Typography, Box } from "@mui/material";
import Logo from "../../assets/logo.png";
import { HiMenuAlt3 as MenuIcon } from "react-icons/hi";
import { makeStyles } from "@mui/styles";
import { PropTypes } from "./TopBar.types";
import { useMainStore } from "../../stores/main";
import { UserContext } from "../../contexts/user";
import { IoShareOutline as Share } from "react-icons/io5";
import { SnackbarKey, useSnackbar } from "notistack";

const useStyles = makeStyles({
	logo: {
		height: 34,
	},
	shareIcon: {
		marginLeft: 8,
		minWidth: 16,
		cursor: "pointer",

		"&:hover": {
			color: "#264a31",
		},
	},
});

const drawerWidth = 280;

export function TopBar(props: PropTypes) {
	const classes = useStyles();
	const currentChat = useMainStore((state) => state.currentChat);
	const { user } = useContext(UserContext);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const { handleDrawerToggle, mobileOpen } = props;

	return (
		<AppBar
			position="fixed"
			elevation={0}
			sx={{
				width: { xs: `calc(100% - ${mobileOpen ? drawerWidth : 0}px)` },
				ml: { xs: `${mobileOpen ? drawerWidth : 0}px` },
				backgroundColor: "#fafafa",
				alignItems: { md: "flex-end", xs: "space-between" },
			}}
		>
			<Toolbar
				sx={{
					justifyContent: "space-between",
					width: { sx: "calc(100% - 32px)", sm: "calc(100% - 48px)" },
				}}
			>
				<IconButton
					aria-label="open drawer"
					edge="start"
					onClick={handleDrawerToggle}
					sx={{
						fontSize: 18,
						mr: 2,
						visibility: { xs: mobileOpen ? "hidden" : "visible" },
					}}
				>
					<MenuIcon />
				</IconButton>
				{currentChat && (
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							maxWidth: "calc(100% - 160px)",
						}}
					>
						<Typography
							sx={{
								textOverflow: "ellipsis",
								overflow: "hidden",
								textWrap: "nowrap",
							}}
						>
							{currentChat.name}
						</Typography>
						{currentChat.user.id === user?.id && (
							<Share
								className={classes.shareIcon}
								onClick={async () => {
									const textToCopy =
										window.location.origin + "/share/" + currentChat.share_id;

									try {
										await navigator.clipboard.writeText(textToCopy);
										const key: SnackbarKey = enqueueSnackbar(
											"Share link copied!",
											{
												variant: "success",
												SnackbarProps: {
													onClick: () => closeSnackbar(key),
												},
											}
										);
									} catch (err) {
										console.error("Error on copying:", err);
										const key: SnackbarKey = enqueueSnackbar(
											"Something went wrong",
											{
												variant: "error",
												SnackbarProps: {
													onClick: () => closeSnackbar(key),
												},
											}
										);
									}
								}}
							/>
						)}
					</Box>
				)}
				<img src={Logo} alt="climeco" className={classes.logo} />
			</Toolbar>
		</AppBar>
	);
}
