import { Box } from "@mui/material";
import { BottomBar, ChatMessages } from "../../components";
import { PropTypes } from "./MainContent.types";
import { useContext } from "react";
import { UserContext } from "../../contexts/user";
import { useMainStore } from "../../stores/main";

export function MainContent(_: PropTypes) {
	const { user } = useContext(UserContext);
	const currentChat = useMainStore((state) => state.currentChat);
	const currentQuestions = useMainStore((state) => state.currentQuestions);
	const setCurrentQuestions = useMainStore(
		(state) => state.setCurrentQuestions
	);

	const showBottomBar = currentChat && currentChat.user.id === user?.id;

	return (
		<Box
			component="main"
			sx={{
				flex: 1,
				height: "calc(100vh - " + (showBottomBar ? 64 : 100) + "px)",
				marginTop: "64px",
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between",
				backgroundColor: "#fff",
				position: "relative",
			}}
		>
			<ChatMessages
				currentQuestions={currentQuestions}
				setCurrentQuestions={setCurrentQuestions}
			/>
			{showBottomBar && <BottomBar />}
		</Box>
	);
}
