import { useState, useEffect } from "react";
import { SideBar, TopBar, MainContent, BasicModal } from "../../components";
import { PropTypes } from "./AppContent.types";
import { Onboarding } from "../Onboarding/Onboarding";

export function AppContent(props: PropTypes) {
	const [mobileOpen, setMobileOpen] = useState(document.body.clientWidth > 600);

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	const [showOnboardingModal, setShowOnboardingModal] = useState(true);

	useEffect(() => {
		/*const hasSeenOnboarding = localStorage.getItem("hasSeenOnboarding");
		if (!hasSeenOnboarding) {
			setShowOnboardingModal(true);
		}*/
	}, []);

	const handleClose = () => {
		/*localStorage.setItem("hasSeenOnboarding", "true");*/
		setShowOnboardingModal(false);
	};

	return (
		<>
			<TopBar mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />
			<SideBar
				mobileOpen={mobileOpen}
				handleDrawerToggle={handleDrawerToggle}
			/>
			<MainContent />
			<BasicModal
				width={600}
				showModal={showOnboardingModal}
				handleClose={handleClose}
				children={<Onboarding handleClose={handleClose} />}
				hideClose
			/>
		</>
	);
}
