import { useContext, useEffect, useState } from "react";
import {
	Box,
	Grid,
	LinearProgress,
	CircularProgress,
	Typography,
} from "@mui/material";
import {
	Question,
	Answer,
	Recommendations,
	BasicModal,
	Score,
} from "../../components";
import { PropTypes } from "./ChatMessages.types";
import { useMainStore } from "../../stores/main";
import { useQuery } from "react-query";
import { SnackbarKey, useSnackbar } from "notistack";
import { animateScroll } from "react-scroll";
import { question as questionActions } from "../../controllers";
import { AnswerSource } from "../AnswerSource";
import BasicAccordion from "../BasicAccordion/BasicAccordion";
import {
	HiOutlineStar as OutlineStar,
	HiMiniStar as Star,
} from "react-icons/hi2";
import { UserContext } from "../../contexts/user";
import { ISource } from "../../models";

export function ChatMessages({
	chat,
	inOnboarding,
	currentQuestions,
	setCurrentQuestions,
	sharedChat,
}: PropTypes) {
	const mainStoreChat = useMainStore((state) => state.currentChat);
	const currentChat = chat || mainStoreChat;
	const setCurrentSources = useMainStore((state) => state.setCurrentSources);
	const showNewChat = useMainStore((state) => state.showNewChat);

	const showScoreModal = useMainStore((state) => state.showScoreModal);
	const setShowScoreModal = useMainStore((state) => state.setShowScoreModal);
	const handleCloseScore = () => setShowScoreModal(false);
	const handleOpenScore = () => setShowScoreModal(true);

	const { user } = useContext(UserContext);

	const [isLoading, setIsLoading] = useState(false);
	const chatMessagesId = "chat-messages" + (inOnboarding ? "-2" : "");

	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const { refetch } = useQuery(
		"questions-" + chatMessagesId + (sharedChat ? "-1" : ""),
		() => questionActions.list(currentChat!.id),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				console.log(res);
				setCurrentQuestions(res);
				if (res.length > 0 && !showNewChat) {
					let auxSources = Array<ISource>();
					res.forEach((question) => {
						question.sources.forEach((source) => {
							if (!auxSources.find((auxSource) => auxSource.id === source.id)) {
								auxSources.push(source);
							}
						});
					});
					setCurrentSources(auxSources);
				}
				setIsLoading(false);
			},
			onError: (error: any) => {
				console.log(error);
				const key: SnackbarKey = enqueueSnackbar("Something went wrong", {
					variant: "error",
					SnackbarProps: {
						onClick: () => closeSnackbar(key),
					},
				});
				setIsLoading(false);
			},
		}
	);

	useEffect(() => {
		if (currentChat && !sharedChat) {
			setCurrentQuestions([]);
			setIsLoading(true);
			refetch();
		}
	}, [refetch, currentChat, setCurrentQuestions, sharedChat]);

	useEffect(() => {
		const scrollToBottom = () => {
			animateScroll.scrollToBottom({
				containerId: chatMessagesId,
				duration: 500,
			});
		};

		if (currentQuestions.length > 0) {
			setTimeout(() => {
				scrollToBottom();
			}, 150);
		}
	}, [currentQuestions, chatMessagesId]);

	return (
		<>
			{currentChat &&
				currentQuestions.length > 1 &&
				!chat &&
				currentChat.user.id === user?.id && (
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							position: "absolute",
							top: 20,
							right: 20,
						}}
						onClick={() => {
							handleOpenScore();
						}}
					>
						{currentChat.rate ? (
							<Star size={14} style={{ marginRight: 4 }} />
						) : (
							<OutlineStar size={14} style={{ marginRight: 4 }} />
						)}

						<Typography
							variant="subtitle1"
							sx={{
								fontSize: 12,
								"&:hover": {
									textDecoration: "underline",
									cursor: "pointer",
								},
							}}
						>
							{currentChat.rate ? currentChat.rate + "/5" : "Rate it"}
						</Typography>
					</Box>
				)}
			<Box
				id={chatMessagesId}
				sx={{
					flex: 1,
					display: "flex",
					flexDirection: "column",
					padding: 4,
					width: { md: "calc(100% - 64px)" },
					maxWidth: 800,
					margin: "0px auto",
					overflow: "auto",
				}}
			>
				{isLoading ? (
					<CircularProgress size={18} sx={{ alignSelf: "center" }} />
				) : currentChat ? (
					currentQuestions.map((question, index) => {
						return (
							<Box key={question.id} sx={{ marginBottom: 2 }}>
								<Question
									question={question}
									currentChat={currentChat}
									showDeleteButton={!inOnboarding}
								/>
								{question.answers.length > 0 ? (
									<>
										<Answer
											answer={question.answers[0]}
											lastAnswer={index === currentQuestions.length - 1}
										/>

										{question.answers[0].answer_sources.length > 0 && (
											<BasicAccordion title="Sources">
												<Grid container spacing={2}>
													{question.answers[0].answer_sources
														.filter((_, index) => index < 10)
														.map((answerSource) => (
															<Grid
																key={answerSource.id}
																item
																xs={12}
																sm={12}
																md={6}
																lg={6}
															>
																<AnswerSource answerSource={answerSource} />
															</Grid>
														))}
												</Grid>
											</BasicAccordion>
										)}
									</>
								) : index === currentQuestions.length - 1 &&
								  new Date().getTime() - 30 * 1000 <
										new Date(question.created_at).getTime() ? (
									<Box sx={{ p: 4 }}>
										<LinearProgress sx={{ color: "#eee" }} />
									</Box>
								) : (
									<Answer
										answer={{
											id: -1,
											message: "There was an error while creating the answer",
											answer_sources: [],
											suggestions: [],
											created_at: question.created_at,
											updated_at: question.updated_at,
										}}
										lastAnswer={index === currentQuestions.length - 1}
										error={true}
									/>
								)}
							</Box>
						);
					})
				) : (
					<Recommendations />
				)}
			</Box>
			<BasicModal
				width={600}
				showModal={showScoreModal}
				handleClose={handleCloseScore}
				children={<Score handleClose={handleCloseScore} />}
			/>
		</>
	);
}
