import React, { useState } from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import {
	HiOutlineStar as OutlineStar,
	HiMiniStar as Star,
} from "react-icons/hi2";
import { PropTypes } from "./Score.types";
import { useMutation } from "react-query";
import { chat as chatActions } from "../../controllers";
import { SnackbarKey, useSnackbar } from "notistack";
import { useMainStore } from "../../stores/main";

export function Score(props: PropTypes) {
	const [rating, setRating] = useState(0);
	const [ratingClicked, setRatingClicked] = useState(false);
	const maxRating = 5;
	const chats = useMainStore((state) => state.chats);
	const setChats = useMainStore((state) => state.setChats);
	const currentChat = useMainStore((state) => state.currentChat);
	const setCurrentChat = useMainStore((state) => state.setCurrentChat);

	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const handleMouseEnter = (index: number) => {
		if (!ratingClicked) {
			setRating(index + 1);
		}
	};

	const handleMouseLeave = () => {
		setRatingClicked(false);
	};

	const handleClick = (index: number) => {
		setRatingClicked(true);
		setRating(index + 1);
	};

	const { isLoading, mutate } = useMutation(
		"chats",
		() => chatActions.update({ chatId: currentChat!.id, rate: rating }),
		{
			retry: false,
			onSuccess: (res) => {
				if (currentChat) {
					currentChat.rate = res.rate;
				}
				setCurrentChat(currentChat);
				let chatIndex = chats.findIndex((chat) => chat.id === currentChat?.id);
				if (chatIndex > -1) {
					chats[chatIndex].rate = res.rate;
					setChats(chats);
				}

				props.handleClose();
			},
			onError: (error: any) => {
				console.log(error);
				const key: SnackbarKey = enqueueSnackbar("Something went wrong", {
					variant: "error",
					SnackbarProps: {
						onClick: () => closeSnackbar(key),
					},
				});
			},
		}
	);

	return (
		<>
			<Typography
				variant="h5"
				sx={{ fontWeight: 700, textAlign: "center", marginBottom: 2 }}
			>
				What is your rating for this chat?
			</Typography>
			<Typography
				sx={{ marginTop: 1, textAlign: "center", marginBottom: 2 }}
				variant="subtitle1"
			>
				Give us your feedback
			</Typography>
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
				}}
				onMouseLeave={handleMouseLeave}
			>
				{Array(maxRating)
					.fill(null)
					.map((_, index) => (
						<div
							key={index}
							onMouseEnter={() => handleMouseEnter(index)}
							onClick={() => handleClick(index)}
							style={{ cursor: "pointer" }}
						>
							{index < rating ? (
								<Star
									size={24}
									style={{ paddingLeft: 4, paddingRight: 4 }}
									color="#00b093"
								/>
							) : (
								<OutlineStar
									size={24}
									style={{ paddingLeft: 4, paddingRight: 4 }}
									color="#00b093"
								/>
							)}
						</div>
					))}
			</Box>
			<Box display="flex" justifyContent="flex-end" sx={{ marginTop: 2 }}>
				<Button
					sx={{ minHeight: 34, minWidth: 100 }}
					variant="contained"
					onClick={() => {
						mutate();
					}}
				>
					{isLoading ? (
						<CircularProgress sx={{ color: "white" }} size={12} />
					) : (
						"Send"
					)}
				</Button>
			</Box>
		</>
	);
}
