import { Typography, Box } from "@mui/material";
import { PropTypes } from "./UploadSources.types";
import { UploadSourceForm } from "..";

export function UploadSources(props: PropTypes) {
	return (
		<Box sx={{ width: "100%" }}>
			<Typography
				variant="h5"
				sx={{ fontWeight: 700, textAlign: "center", marginBottom: 2 }}>
				Upload data
			</Typography>
			<Box
				sx={{
					borderBottom: 1,
					borderColor: "divider",
					display: "flex",
					justifyContent: "center",
					marginBottom: 4,
				}}></Box>
			<UploadSourceForm successCallback={props.successCallback} />
		</Box>
	);
}
