import { IUser } from "../../models"
import { connectionsClient } from "../../utils/ConnectionsClient"

class User {
    async list(): Promise<Array<IUser>> {
        const response = await connectionsClient.client.get("users");
        return response.data;
    }
}

export const user = new User();