import { Box, Typography } from "@mui/material";
import { PropTypes } from "./SuggestedTemplate.types";

export function SuggestedTemplate(props: PropTypes) {
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
				border:
					props.selectedTemplate &&
					props.selectedTemplate.id === props.template.id
						? "1px solid #00b093"
						: "1px solid #ececec",
				borderRadius: 1,
				padding: 2,
				cursor: props.disabled ? "default" : "pointer",
				minHeight: 100,
				"&:hover": {
					border: props.disabled ? "1px solid #ececec" : "1px solid #00b093",
				},
				opacity: props.disabled ? 0.5 : 1,
			}}
			onClick={(e) => {
				if (!props.disabled) {
					props.callback(
						props.selectedTemplate
							? props.selectedTemplate.id === props.template.id
							: false
					);
				}
			}}
		>
			<Typography
				variant="body2"
				gutterBottom
				sx={{
					fontSize: "14px",
					textAlign: "center",
				}}
			>
				{props.template.name}
			</Typography>
			<Typography
				variant="body2"
				gutterBottom
				sx={{
					fontSize: "12px",
					textAlign: "center",
					display: "-webkit-box",
					WebkitBoxOrient: "vertical",
					WebkitLineClamp: 3,
					overflow: "hidden",
					textOverflow: "ellipsis",
				}}
			>
				{props.template.description}
			</Typography>
		</Box>
	);
}
