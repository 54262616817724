import {
	Button,
	CircularProgress,
	TextField,
	Box,
	Typography,
	InputAdornment,
	IconButton,
} from "@mui/material";
import { PropTypes } from "./Auth.types";
import { useRef, useState } from "react";
import { token as tokenActions } from "../../controllers";
import { useMutation } from "react-query";
import { SnackbarKey, useSnackbar } from "notistack";
import { useContext } from "react";
import { UserContext } from "../../contexts/user";
import { makeStyles } from "@mui/styles";
import {
	AiOutlineEye as Visibility,
	AiOutlineEyeInvisible as VisibilityOff,
} from "react-icons/ai";
import Logo from "../../assets/logo.png";

const useStyles = makeStyles({
	loginContainer: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		flex: 1,
		height: "100vh",
		padding: 20,
	},
	loginContent: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		width: "100%",
		maxWidth: "500px",
		marginTop: -90,
	},
	inputLabel: {
		textTransform: "uppercase",
		fontSize: 10,
		marginTop: 16,
		marginBottom: 4,
		fontWeight: 700,
		color: "#686868",
		width: "100%",
	},
	mainButton: {
		height: 40,
		minWidth: "120px",
	},
});

export function Auth(props: PropTypes) {
	const classes = useStyles();
	//const [email, setEmail] = useState("");
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const passwordInputRef = useRef<HTMLInputElement | null>(null);
	const [showPassword, setShowPassword] = useState(false);

	const handleClickShowPassword = () => setShowPassword((show) => !show);

	const handleMouseDownPassword = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		event.preventDefault();
	};

	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const { getUser } = useContext(UserContext);
	const { isLoading, mutate } = useMutation(
		"token",
		() => {
			return tokenActions.login({ username: username, password: password });
		},
		{
			onSuccess: () => {
				getUser();
			},
			onError: (error) => {
				console.log(error);
				const key: SnackbarKey = enqueueSnackbar("Something went wrong", {
					variant: "error",
					SnackbarProps: {
						onClick: () => closeSnackbar(key),
					},
				});
			},
		}
	);

	const login = () => {
		mutate();
	};

	const onKeyPressName = (e: React.KeyboardEvent) => {
		if (e.key === "Enter" && !e.shiftKey && !e.ctrlKey) {
			e.preventDefault();
			if (passwordInputRef.current) {
				passwordInputRef.current.focus();
			}
		}
	};

	const onKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === "Enter" && !e.shiftKey && !e.ctrlKey) {
			e.preventDefault();
			login();
		}
	};

	/*const validateEmail = (email: string) => {
		return String(email)
			.toLowerCase()
			.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			);
	};*/

	return (
		<Box className={classes.loginContainer}>
			<Box className={classes.loginContent}>
				<img
					src={Logo}
					alt="climeco"
					style={{ width: 150, marginBottom: 20 }}
				/>
				<Typography
					variant="body2"
					sx={{ textAlign: "center", marginBottom: 4 }}
				>
					ClimeCo Policy AI Tool
				</Typography>
				<Typography
					variant="h5"
					sx={{ fontWeight: 700, textAlign: "center", marginBottom: 4 }}
				>
					Sign in
				</Typography>

				<TextField
					InputLabelProps={{ shrink: false }}
					fullWidth
					label=""
					id="username"
					placeholder="Username"
					sx={{ marginBottom: 2 }}
					onChange={(e) => {
						setUsername(e.target.value);
					}}
					onKeyDown={onKeyPressName}
				/>
				<TextField
					inputRef={passwordInputRef}
					type={showPassword ? "text" : "password"}
					InputLabelProps={{ shrink: false }}
					fullWidth
					label=""
					id="password"
					placeholder="Password"
					onKeyDown={onKeyPress}
					sx={{ marginBottom: 8 }}
					onChange={(e) => {
						setPassword(e.target.value);
					}}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton
									aria-label="toggle password visibility"
									onClick={handleClickShowPassword}
									onMouseDown={handleMouseDownPassword}
									edge="end"
								>
									{showPassword ? <VisibilityOff /> : <Visibility />}
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
				<Button
					disabled={/*!email ||*/ !username || !password}
					variant="contained"
					onClick={() => {
						if (true /*validateEmail(email)*/) {
							login();
						} else {
							const key: SnackbarKey = enqueueSnackbar("Something went wrong", {
								variant: "error",
								SnackbarProps: {
									onClick: () => closeSnackbar(key),
								},
							});
						}
					}}
					className={classes.mainButton}
				>
					{isLoading ? <CircularProgress size={12} /> : "Login"}
				</Button>
			</Box>
		</Box>
	);
}
