
import axios from "axios";
import { IToken } from "../../models";

class ConnectionsClient {
	accessTokenKey = "access_token";
	refreshTokenKey = "refresh_token";
	token: IToken = {
		access: localStorage.getItem('access_token'),
		refresh: localStorage.getItem('refresh_token')
	}

	client = axios.create({
		baseURL:
			"https://stage-policy-ai.azurewebsites.net/policy-ai-tool/api/", //process.env.REACT_APP_API_URL"http://localhost:8000/policy-ai-tool/api/"*/,
		timeout: 60000 * 3, // 30 minutes for now until AZURE OPEN API embeddings problem is fixed
		headers: {
			"Content-type": "application/json",
		},
	});

	constructor() {
		this.client.interceptors.request.use((config) => {
			config.headers.Authorization = this.token.access ? `Bearer ${this.token.access}` : '';
			console.log(config.url)

			const queryStringWithoutSlashIndex = config.url?.includes("?") && !config.url?.includes("/?")
			if (queryStringWithoutSlashIndex) {
				config.url?.replace("?", "/?");
			}
			else if (config.url && config.url[config.url.length - 1] !== '/') {
				config.url += '/';
			}

			return config;
		});

		this.client.interceptors.response.use((response) => {
			return response;
		}, async (error) => {
			console.log(error);
			const originalRequest = error.config;
			if (error.response.status === 403 && !originalRequest._retry) {
				originalRequest._retry = true;
				try {
					await this.refreshAccessToken();
					return this.client(originalRequest);
				}
				catch (error) {
					console.log(error);
				}
			}

			return Promise.reject(error);
		});
	}

	updateTokens = (token: IToken) => {
		this.token = token;
		if (this.token.access && this.token.refresh) {
			localStorage.setItem(this.accessTokenKey, this.token.access);
			localStorage.setItem(this.refreshTokenKey, this.token.refresh);
		}
		else {
			localStorage.removeItem(this.accessTokenKey);
			localStorage.removeItem(this.refreshTokenKey);
		}

	}

	refreshAccessToken = async () => {
		try {
			if (this.token.refresh) {
				const res = await this.client.post("token/refresh", { refresh: this.token.refresh });
				const accessToken = res.data.access;
				this.updateTokens({ access: accessToken, refresh: this.token.refresh });
			}
		}
		catch (error) {
			this.updateTokens({ access: null, refresh: null });
			window.location.reload();
		}
	}
}

export const connectionsClient = new ConnectionsClient();
