import { createTheme } from "@mui/material/styles";

const theme = createTheme({
	typography: {
		fontFamily: "Raleway, Arial",
	},
	palette: {
		primary: {
			main: "#00b093",
		},
	},
	components: {
		MuiButtonBase: {
			defaultProps: {
				disableRipple: true,
			},
		},
		MuiButton: {
			defaultProps: {
				disableElevation: true,
			},
			styleOverrides: {
				root: {
					fontSize: "12px",
					borderRadius: 8,
					cursor: "pointer",
					"&:disabled": {
						backgroundColor: "#00b093",
						opacity: 0.4,
						color: "#fff",
					},
				},
				containedPrimary: {
					color: "white"
				}
			},
		},
		MuiTab: {
			styleOverrides: {
				root: {
					fontSize: '11px',
					color: "rgba(0, 0, 0, 0.5)",
					'&.Mui-selected': {
						fontWeight: 700,
						color: "rgba(0, 0, 0, 1)",
					},
					textAlign: "left",
					padding: 0

				},

			},

		},
		MuiAutocomplete: {
			styleOverrides: {
				root: {
					overflow: "hidden",
				},
				inputRoot: {
					overflow: "hidden",
				},
			},
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					boxShadow: "none",
					border: "1px solid #f0f0f0"
				},
			},
		},
		MuiTextField: {
			styleOverrides: {
				root: {
					backgroundColor: "#fafafa",
					"& fieldset": { border: "none", height: "60px" },
					marginRight: 2,
				},
			},
		},
	},
});

export default theme;
