import { useContext } from "react";
import { Box } from "@mui/material";
import { AppContent, Auth, OverlayLoading } from "../../components";
import { PropTypes } from "./MainLayout.types";
import { UserContext } from "../../contexts/user";
import { Routes as Switch, Route, Navigate } from "react-router-dom";
import { SharedChat } from "../SharedChat";

export function MainLayout(props: PropTypes) {
	const { user, isLoading } = useContext(UserContext);

	return (
		<Box sx={{ display: "flex" }}>
			<Switch>
				<Route path="/share/:shareId" element={<SharedChat />} />
				<Route
					path="/"
					element={
						isLoading ? <OverlayLoading /> : user ? <AppContent /> : <Auth />
					}
				/>
				<Route path="*" element={<Navigate to="/" replace />} />
			</Switch>
		</Box>
	);
}
