import { Box, CircularProgress, Typography } from "@mui/material";
import { PropTypes } from "./SharedChat.types";
import { ChatMessages } from "../ChatMessages";
import { useState } from "react";
import { IChat } from "../../models";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { SnackbarKey, useSnackbar } from "notistack";
import { chat as chatActions } from "../../controllers";
import { useNavigate } from "react-router-dom";

export function SharedChat(props: PropTypes) {
	let { shareId } = useParams();
	const [chat, setChat] = useState<IChat | null>(null);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const navigate = useNavigate();

	const { isLoading, isFetching, isRefetching } = useQuery(
		"shared-chat",
		() => chatActions.getSharedOne(shareId ?? ""),
		{
			enabled: true,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				console.log(res);
				setChat(res);
			},
			onError: (error: any) => {
				console.log(error);
				const key: SnackbarKey = enqueueSnackbar("Something went wrong", {
					variant: "error",
					SnackbarProps: {
						onClick: () => closeSnackbar(key),
					},
				});
				setTimeout(() => {
					navigate("/");
				}, 3000);
			},
		}
	);

	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				margin: "auto",
			}}
		>
			{isLoading || isFetching || isRefetching ? (
				<Box
					sx={{
						display: "flex",
						alignContent: "center",
						justifyContent: "center",
					}}
				>
					<CircularProgress size={24} />
				</Box>
			) : chat ? (
				<ChatMessages
					chat={chat}
					currentQuestions={chat?.questions}
					setCurrentQuestions={() => {}}
					inOnboarding={true}
					sharedChat={true}
				/>
			) : (
				<Typography sx={{ padding: 4, textAlign: "center" }}>
					There's been an error
				</Typography>
			)}
		</Box>
	);
}
