import { Button, Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
	inputLabel: {
		textTransform: "uppercase",
		fontSize: 10,
		marginTop: 16,
		marginBottom: 4,
		fontWeight: 700,
		color: "#686868",
	},
	fileContainer: {
		backgroundColor: "#fafafa",
		padding: 20,
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
	},
});

export function UploadFile() {
	const classes = useStyles();

	return (
		<>
			<span className={classes.inputLabel}>File</span>
			<Box className={classes.fileContainer}>
				<Button variant="outlined" sx={{ maxWidth: 300, marginTop: 2 }}>
					Upload file
				</Button>
				<Typography
					variant="body2"
					sx={{
						textAlign: "center",
						marginTop: 1,
					}}
				>
					No file yet
				</Typography>
			</Box>
		</>
	);
}
