import { ISource } from "../../models";
import { connectionsClient } from "../../utils/ConnectionsClient";
import { SourceTypes } from "./source.types";

class Source {
	async list(search?: string): Promise<Array<ISource>> {
		const response = await connectionsClient.client.get(`sources`, {
			params: { limit: 999, ordering: "-created_at", search: search ?? "" },
		});
		return response.data;
	}

	async getOne(id: number): Promise<ISource> {
		const response = await connectionsClient.client.get(`sources/${id}`);
		return response.data;
	}

	async create(params: SourceTypes.Create): Promise<ISource> {
		console.log(params)
		const formData = new FormData();
		Object.entries(params).forEach(([key, value]) => {
			const valueType = typeof value;
			switch (valueType) {
				case "undefined":
				case "object":
					if (!value && key !== "file") {
						formData.append(key, "");
					}
					else if (Array.isArray(value)) {
						formData.append(key, JSON.stringify(value));
					}
					else if (value) {
						formData.append(key, value);
					}
					break;
				default:
					formData.append(key, value!.toString());
			}
		});
		console.log(formData.get("file"))
		const response = await connectionsClient.client.post(`sources`, formData, {
			headers: {
				"Content-Type": "multipart/form-data"
			}
		});
		return response.data;
	}
}

export const source = new Source();
