import { useState } from "react";
import { Box, Typography } from "@mui/material";
import { PropTypes } from "./ChatItem.types";
import { makeStyles } from "@mui/styles";
import {
	HiOutlineChatBubbleLeft as Bubble,
	HiOutlineTrash as Trash,
} from "react-icons/hi2";
import { useMainStore } from "../../stores/main";

const useStyles = makeStyles({
	chatItem: {
		display: "flex",
		alignItems: "center",
		padding: "12px 8px",
		borderRadius: 2,
		textAlign: "left",
		cursor: "pointer",
		marginBottom: 8,
		flexDirection: "row",
		justifyContent: "space-between",
	},
	chatItemSelected: {
		color: "#00b093",
		background: "#00b09314",
		boxShadow: "-3px 0 0 #00b093",
	},
	chatItemText: {
		overflow: "hidden",
		textOverflow: "ellipsis",
		display: "-webkit-box",
		"-webkit-box-orient": "vertical",
		"-webkit-line-clamp": "1",
		marginBottom: "0px!important",
		cursor: "pointer",
	},
	chatItemTextSelected: {
		fontWeight: 700,
	},
	chatItemInfo: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
	trashIcon: {
		marginLeft: 8,
		minWidth: 16,
		cursor: "pointer",

		"&:hover": {
			color: "#264a31",
		},
	},
});

export function ChatItem({
	chat,
	title,
	handleDeleteModalOpen,
	showDeleteButton,
}: PropTypes) {
	const classes = useStyles();
	const currentChat = useMainStore((state) => state.currentChat);
	const setCurrentChat = useMainStore((state) => state.setCurrentChat);

	const selectChat = () => {
		setCurrentChat(chat);
	};

	const [showTrashIcon, setShowTrashIcon] = useState(false);

	return (
		<Box
			onMouseEnter={() => {
				return setShowTrashIcon(true);
			}}
			onMouseLeave={() => {
				return setShowTrashIcon(false);
			}}
			className={
				currentChat?.id === chat.id
					? classes.chatItemSelected + " " + classes.chatItem
					: classes.chatItem
			}
			onClick={selectChat}
		>
			<Box className={classes.chatItemInfo}>
				<Bubble style={{ marginRight: 8, minWidth: 16 }} />
				<Typography
					variant="body2"
					gutterBottom
					className={
						currentChat?.id === chat.id
							? classes.chatItemTextSelected + " " + classes.chatItemText
							: classes.chatItemText
					}
				>
					{title}
				</Typography>
			</Box>
			{showDeleteButton && (showTrashIcon || currentChat?.id === chat.id) ? (
				<Trash
					className={classes.trashIcon}
					onClick={() => {
						handleDeleteModalOpen();
					}}
				/>
			) : null}
		</Box>
	);
}
