import { Button } from "@mui/material";
import { FiUpload } from "react-icons/fi";
import { PropTypes } from "./UploadSourcesButton.types";

export function UploadSourcesButton(props: PropTypes) {
	const { handleOpen } = props;
	return (
		<Button variant="contained" onClick={handleOpen}>
			<FiUpload style={{ marginRight: 6, fontSize: 12 }} />
			Upload sources
		</Button>
	);
}
