import React, { useState } from "react";
import { IUser } from "../../models";
import { UserContextType, PropTypes } from "./UserContext.types";
import { user as userActions } from "../../controllers";
import { useQuery } from "react-query";

export const UserContext = React.createContext<UserContextType>({
	user: null,
	setUser: (user: IUser | null) => {},
	getUser: () => {},
	isLoading: true,
});

export const UserProvider: React.FC<PropTypes> = ({ children }) => {
	const [user, setUser] = useState<IUser | null>(null);
	const { isLoading, refetch } = useQuery("users", userActions.list, {
		enabled: true,
		refetchOnWindowFocus: false,
		retry: false,
		onSuccess: (res) => {
			console.log(res);
			setUser(res[0]);
		},
		onError: (error: any) => {
			console.log(error);
			setUser(null);
		},
	});

	const getUser = () => {
		refetch();
	};

	return (
		<UserContext.Provider value={{ user, setUser, getUser, isLoading }}>
			{children}
		</UserContext.Provider>
	);
};
