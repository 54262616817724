import { ITag } from "../../models"
import { connectionsClient } from "../../utils/ConnectionsClient"

class Tag {

    async list(search?: string): Promise<Array<ITag>> {
        const response = await connectionsClient.client.get(`tags`, {
            params: { limit: 999, ordering: "-created_at", search: search ?? "" },
        });
        return response.data;
    }
}

export const tag = new Tag();