import { IToken } from "../../models"
import { connectionsClient } from "../../utils/ConnectionsClient"

export type LoginParameters = {
    username: string
    password: string
}

export class Token {

    login = async ({ username, password }: LoginParameters): Promise<IToken> => {
        const response = await connectionsClient.client.post("token", {
            username,
            password
        });
        connectionsClient.updateTokens({ access: response.data.access, refresh: response.data.refresh });

        return response.data;
    }
}

export const token = new Token();
