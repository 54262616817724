import { Button } from "@mui/material";
import { PropTypes } from "./NewChat.types";
import { FiPlus } from "react-icons/fi";

export function NewChat(props: PropTypes) {
	const { handleOpenNewChat } = props;

	const createChat = () => {
		handleOpenNewChat();
	};

	return (
		<Button variant="outlined" onClick={createChat} sx={{ minHeight: "33px" }}>
			<>
				<FiPlus style={{ marginRight: 6, fontSize: 12 }} />
				New Chat
			</>
		</Button>
	);
}
