import { Box, CircularProgress } from "@mui/material";
import { PropTypes } from "./OverlayLoading.types";

export function OverlayLoading(props: PropTypes) {
	return (
		<Box
			sx={{
				position: "absolute",
				top: 0,
				bottom: 0,
				left: 0,
				right: 0,
				display: "flex",
				justifyContent: "center",
				width: "100%",
				paddingTop: "45vh",
			}}>
			<CircularProgress size={48} />
		</Box>
	);
}
