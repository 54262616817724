import { useState, useEffect } from "react";
import {
	Typography,
	Box,
	Autocomplete,
	TextField,
	Divider,
} from "@mui/material";
import { PropTypes } from "./MultiItemSelector.types";
import { HiOutlineTrash as Trash } from "react-icons/hi2";
import IconButton from "@mui/material/IconButton";
import { ISource, ITag } from "../../models";

export function MultiItemSelector({
	title,
	placeholderAutocomplete,
	buttonTitle,
	listTitle,
	emptyState,
	elements,
	selectedElements,
	setSelectedElements,
	setFilter,
	onboarding,
}: PropTypes) {
	const [availableElements, setAvailableElements] = useState(elements);
	const [selectedElement, setSelectedElement] = useState<ISource | ITag | null>(
		null
	);

	const removeElement = (indexToRemove: number) => {
		const updatedElements = [...selectedElements];
		updatedElements.splice(indexToRemove, 1);
		setSelectedElements(updatedElements);
	};

	useEffect(() => {
		const updatedAvailableElements = elements.filter(
			(element) =>
				!selectedElements.some(
					(selectedElement) => selectedElement?.id === element.id
				)
		);
		setAvailableElements(updatedAvailableElements);
	}, [selectedElements, elements]);

	return (
		<Box sx={{ width: "100%" }}>
			{!onboarding && (
				<>
					{" "}
					<Typography
						variant="h5"
						sx={{ fontWeight: 700, textAlign: "center", marginBottom: 2 }}
					>
						{title}
					</Typography>
					<Box
						sx={{
							borderBottom: 1,
							borderColor: "divider",
							display: "flex",
							justifyContent: "center",
							marginBottom: 4,
						}}
					></Box>
				</>
			)}
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					marginBottom: 4,
				}}
			>
				<Autocomplete
					disablePortal
					id="source-autocomplete"
					ListboxProps={{ style: { maxHeight: 150 } }}
					options={availableElements}
					sx={{
						width: 300,
						maxHeight: "200px",
						overflowY: "auto",
						zIndex: 2000,
						"& input": {
							fontSize: 14,
						},
					}}
					onChange={(_, value) => {
						setSelectedElements([...selectedElements, value!]);
						setSelectedElement(null);
					}}
					value={selectedElement}
					blurOnSelect={true}
					clearOnBlur={true}
					renderOption={(props, option) => {
						return (
							<li {...props} key={option.id}>
								{option.name}
							</li>
						);
					}}
					getOptionLabel={(option) => option.name}
					renderInput={(params) => (
						<TextField
							{...params}
							InputLabelProps={{ shrink: false }}
							fullWidth
							label=""
							placeholder={placeholderAutocomplete}
							onChange={(e) => {
								setFilter(e.target.value);
							}}
						/>
					)}
				/>
			</Box>

			<Typography variant="body2" sx={{ marginBottom: 2 }}>
				{listTitle}
			</Typography>
			<Box
				sx={{
					minHeight: 45,
					maxHeight: "calc(100vh - 512px)",
					backgroundColor: "#fafafa",
					padding: 2,
					overflow: "auto",
				}}
			>
				{selectedElements && selectedElements.length > 0 ? (
					selectedElements.map((element, index) => {
						return (
							<Box key={element.id}>
								<Box
									sx={{
										display: "flex",
										justifyContent: "space-between",
									}}
								>
									<Typography variant="body2" key={element?.id}>
										{element?.name}
									</Typography>
									<IconButton
										color="primary"
										aria-label="remove source"
										size="small"
										onClick={() => {
											removeElement(index);
										}}
									>
										<Trash />
									</IconButton>
								</Box>
								<Divider sx={{ opacity: 0.3, margin: "14px 8px" }} />
							</Box>
						);
					})
				) : (
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							height: "100%",
						}}
					>
						<Typography variant="body2" sx={{ marginBottom: 2, opacity: 0.4 }}>
							{emptyState}
						</Typography>
					</Box>
				)}
			</Box>
		</Box>
	);
}
