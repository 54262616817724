import { useEffect, useState } from "react";
import { Box, CircularProgress, Divider, Tab, Tabs } from "@mui/material";
import { ChatItem } from "../ChatItem";
import { chat as chatActions } from "../../controllers";
import { PropTypes } from "./ChatList.types";
import { BasicModal, DeleteConfirmation, Search } from "../../components";
import { useMainStore } from "../../stores/main";
import { useMutation, useQuery } from "react-query";
import { SnackbarKey, useSnackbar } from "notistack";

export function ChatList(_: PropTypes) {
	const chats = useMainStore((state) => state.chats);
	const setChats = useMainStore((state) => state.setChats);
	const currentChat = useMainStore((state) => state.currentChat);
	const setCurrentChat = useMainStore((state) => state.setCurrentChat);
	const [search, setSearch] = useState("");

	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const handleDeleteModalOpen = () => setShowDeleteModal(true);
	const handleDeleteModalClose = () => setShowDeleteModal(false);

	const [tabValue, setTabValue] = useState(0);

	const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
		setTabValue(newValue);
	};

	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	function a11yProps(index: number) {
		return {
			id: `simple-tab-${index}`,
			"aria-controls": `simple-tabpanel-${index}`,
		};
	}

	const { isRefetching: isLoadingChats, refetch } = useQuery(
		"chats",
		() => chatActions.list(search),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				console.log(res);
				setChats(res);
			},
			onError: (error: any) => {
				console.log(error);
				const key: SnackbarKey = enqueueSnackbar("Something went wrong", {
					variant: "error",
					SnackbarProps: {
						onClick: () => closeSnackbar(key),
					},
				});
			},
		}
	);

	const { isRefetching: isLoadingSavedChats, refetch: refetchSaved } = useQuery(
		"saved-chats",
		() => chatActions.list(search, undefined, true),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				console.log(res);
				setChats(res);
			},
			onError: (error: any) => {
				console.log(error);
				const key: SnackbarKey = enqueueSnackbar("Something went wrong", {
					variant: "error",
					SnackbarProps: {
						onClick: () => closeSnackbar(key),
					},
				});
			},
		}
	);

	const { mutate: mutateSavedChats } = useMutation(
		"update-saved-chats",
		() => chatActions.updateSavedChats({ chatId: currentChat!.id }),
		{
			retry: false,
			onSuccess: (res) => {
				console.log(res);
				refetchSaved();
				setShowDeleteModal(false);
				setCurrentChat(null);
			},
			onError: (error: any) => {
				console.log(error);
				const key: SnackbarKey = enqueueSnackbar("Something went wrong", {
					variant: "error",
					SnackbarProps: {
						onClick: () => closeSnackbar(key),
					},
				});
			},
		}
	);

	useEffect(() => {
		if (tabValue === 0) {
			refetch();
		} else {
			refetchSaved();
		}
	}, [search, tabValue, refetch, refetchSaved]);

	const { mutate } = useMutation(
		"chats",
		() => chatActions.delete(currentChat!),
		{
			onSuccess: (res) => {
				console.log(res);
				refetch();
				setShowDeleteModal(false);
				setCurrentChat(null);
			},
			onError: (error: any) => {
				console.log(error);
				const key: SnackbarKey = enqueueSnackbar("Something went wrong", {
					variant: "error",
					SnackbarProps: {
						onClick: () => closeSnackbar(key),
					},
				});
			},
		}
	);

	const deleteChat = () => {
		if (tabValue === 0) {
			mutate();
		} else {
			mutateSavedChats();
		}
	};

	return (
		<>
			<Box sx={{ height: "calc(100vh - 360px)" }}>
				<Tabs
					TabIndicatorProps={{
						style: {
							display: "none",
						},
					}}
					sx={{
						marginBottom: "12px",
					}}
					value={tabValue}
					onChange={handleTabChange}
					aria-label="chats tabs "
				>
					<Tab label="YOUR CHATS" {...a11yProps(0)} />
					<Tab label="SAVED CHATS" {...a11yProps(1)} />
				</Tabs>
				<Search setSearch={setSearch} />
				<Divider sx={{ opacity: 0.3, margin: "14px 8px" }} />
				<Box sx={{ height: "calc(100% - 25px)", overflow: "auto" }}>
					{!isLoadingSavedChats && !isLoadingChats ? (
						chats.map((chat) => {
							return (
								<ChatItem
									key={chat.id}
									chat={chat}
									title={chat.name}
									showDeleteButton={tabValue === 1}
									handleDeleteModalClose={handleDeleteModalClose}
									handleDeleteModalOpen={handleDeleteModalOpen}
								/>
							);
						})
					) : (
						<CircularProgress size={20} />
					)}
				</Box>
			</Box>
			<BasicModal
				width={600}
				showModal={showDeleteModal}
				handleClose={handleDeleteModalClose}
				children={
					<DeleteConfirmation
						title={currentChat?.name ?? ""}
						handleDeleteModalClose={handleDeleteModalClose}
						itemType="chat"
						deleteItem={deleteChat}
					/>
				}
			/>
		</>
	);
}
