import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Avatar from "react-avatar";
import { PropTypes } from "./Question.types";
import { useMainStore } from "../../stores/main";
import { useMutation } from "react-query";
import { question as questionActions } from "../../controllers";
import { SnackbarKey, useSnackbar } from "notistack";
import { HiOutlineTrash as Trash } from "react-icons/hi2";
import { useState } from "react";
import { BasicModal } from "../BasicModal";
import { DeleteConfirmation } from "../DeleteConfirmation";

const useStyles = makeStyles({
	trashIcon: {
		position: "absolute",
		marginLeft: 8,
		minWidth: 16,
		cursor: "pointer",
		right: 5,
		top: 5,
		background: "white",
		padding: 4,
		borderRadius: "50%",
		border: "1px solid rgb(0,0,0,0.1)",

		"&:hover": {
			color: "#264a31",
		},
	},
});

export function Question({
	question,
	currentChat,
	showDeleteButton,
}: PropTypes) {
	const classes = useStyles();
	const currentQuestions = useMainStore((state) => state.currentQuestions);
	const setCurrentQuestions = useMainStore(
		(state) => state.setCurrentQuestions
	);
	const [showTrashIcon, setShowTrashIcon] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const handleDeleteModalOpen = () => setShowDeleteModal(true);
	const handleDeleteModalClose = () => setShowDeleteModal(false);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const { mutate } = useMutation(
		"questions",
		() =>
			questionActions.delete({
				chatId: currentChat!.id,
				questionId: question.id,
			}),
		{
			onSuccess: (res) => {
				const auxQuestions = [...currentQuestions];
				const index = auxQuestions.findIndex((q) => q.id === question.id);
				if (index > -1) {
					auxQuestions.splice(index, 1);
				}
				setCurrentQuestions([...auxQuestions]);
			},
			onError: (error: any) => {
				console.log(error);
				const key: SnackbarKey = enqueueSnackbar("Something went wrong", {
					variant: "error",
					SnackbarProps: {
						onClick: () => closeSnackbar(key),
					},
				});
			},
		}
	);

	const deleteQuestion = () => {
		if (question.id < 0) {
			const auxQuestions = [...currentQuestions];
			const index = auxQuestions.findIndex((q) => q.id === question.id);
			if (index > -1) {
				auxQuestions.splice(index, 1);
			}
			setCurrentQuestions(auxQuestions);
		} else {
			mutate();
		}
	};

	return (
		<>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "left",
					alignItems: "flex-start",
					marginTop: 2,
					position: "relative",
				}}
				onMouseEnter={() => {
					return setShowTrashIcon(true);
				}}
				onMouseLeave={() => {
					return setShowTrashIcon(false);
				}}
			>
				<Avatar
					name={currentChat?.user.username}
					size="34"
					round="6px"
					style={{ fontSize: "18px", marginRight: "12px" }}
				></Avatar>
				<Typography
					variant="body2"
					gutterBottom
					sx={{
						marginTop: "8px",
						marginBottom: 0,
						fontWeight: 500,
					}}
				>
					{question.message}
				</Typography>
				{showDeleteButton && showTrashIcon ? (
					<Trash
						className={classes.trashIcon}
						onClick={() => {
							handleDeleteModalOpen();
						}}
					/>
				) : null}
			</Box>
			<BasicModal
				width={600}
				showModal={showDeleteModal}
				handleClose={handleDeleteModalClose}
				children={
					<DeleteConfirmation
						title={question.message}
						handleDeleteModalClose={handleDeleteModalClose}
						itemType="question"
						deleteItem={deleteQuestion}
					/>
				}
			/>
		</>
	);
}
