import { Box, Typography } from "@mui/material";
import { PropTypes } from "./SuggestedChat.types";
import { HiMiniStar as Star } from "react-icons/hi2";

export function SuggestedChat(props: PropTypes) {
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				border: "1px solid #ececec",
				borderRadius: 1,
				padding: 2,
				cursor: "pointer",
				minHeight: 100,
				"&:hover": {
					border: "1px solid #00b093",
				},
			}}
			onClick={props.callback}
		>
			<Typography
				variant="body2"
				gutterBottom
				sx={{
					fontSize: "14px",
					textAlign: "center",
					marginBottom: 0,
					color: "#686868",
					fontWeight: 500,
					display: "-webkit-box",
					WebkitBoxOrient: "vertical",
					WebkitLineClamp: 3,
					overflow: "hidden",
					textOverflow: "ellipsis",
					flex: 1,
				}}
			>
				{props.title}
			</Typography>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					justifyContent: props.rate ? "space-between" : "flex-end",
					alignItems: "center",
				}}
			>
				{props.rate !== null && props.rate !== undefined && (
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
						}}
					>
						<Typography sx={{ fontSize: 12, marginRight: 1 }} variant="body2">
							{props.rate}/5
						</Typography>
						<Star size={16} color="#00b093" />
					</Box>
				)}

				<Typography sx={{ fontSize: 12, textAlign: "right" }} variant="body2">
					{props.user && props.user}
				</Typography>
			</Box>
		</Box>
	);
}
