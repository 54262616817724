import { Box, Typography } from "@mui/material";
import { PropTypes } from "./RecommendationItem.types";

export function RecommendationItem(props: PropTypes) {
	return (
		<Box
			sx={{
				border: "1px solid #ececec",
				borderRadius: 1,
				padding: 2,
				cursor: "pointer",
				flex: 1,
			}}
			onClick={props.callback}
		>
			<Typography
				variant="body2"
				gutterBottom
				sx={{
					fontSize: "14px",
					textAlign: "center",
					marginBottom: 0,
					color: "#686868",
					fontWeight: 700,
				}}
			>
				{props.title}
			</Typography>
			<Typography
				variant="body2"
				gutterBottom
				sx={{
					fontSize: "14px",
					textAlign: "center",
					marginBottom: 0,
					color: "#686868",
				}}
			>
				{props.subtitle}
			</Typography>
		</Box>
	);
}
