import { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import Typewriter from "typewriter-effect";
import { PropTypes } from "./Answer.types";
import { useMainStore } from "../../stores/main";
import ErrorIcon from "@mui/icons-material/Error";
import {
	HiOutlineClipboard as Clipboard,
	HiOutlineCheckCircle as Check,
} from "react-icons/hi2";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
	clipboardIcon: {
		marginLeft: 8,
		minWidth: 16,
		cursor: "pointer",

		"&:hover": {
			color: "#264a31",
		},
	},
	checkIcon: {
		marginLeft: 8,
		minWidth: 16,
		cursor: "pointer",
		color: "#00b093",

		"&:hover": {
			color: "#264a31",
		},
	},
});

export function Answer({ answer, lastAnswer, error }: PropTypes) {
	const shouldType = useMainStore((state) => state.shouldType);
	const setShouldType = useMainStore((state) => state.setShouldType);
	const setShowScoreModal = useMainStore((state) => state.setShowScoreModal);
	const currentChat = useMainStore((state) => state.currentChat);
	const currentQuestions = useMainStore((state) => state.currentQuestions);
	const [isCopied, setIsCopied] = useState(false);
	const classes = useStyles();

	const handleCopyToClipboard = async () => {
		const textToCopy = answer.message;

		try {
			await navigator.clipboard.writeText(textToCopy);
			setIsCopied(true);
		} catch (err) {
			console.error("Error on copying:", err);
		}
	};

	useEffect(() => {
		if (isCopied) {
			const timeout = setTimeout(() => {
				setIsCopied(false);
			}, 3000);
			return () => clearTimeout(timeout);
		}
	}, [isCopied]);

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				justifyContent: "left",
				alignItems: "center",
				backgroundColor: "#fafafa",
				padding: 3,
				marginTop: 1,
				fontSize: 14,
				lineHeight: 1.8,
				"& p, & span": {
					whiteSpace: "pre-wrap",
					wordBreak: "break-word",
				},
			}}
		>
			{error && <ErrorIcon sx={{ color: "#9a4a4a", marginRight: 1 }} />}
			{shouldType && lastAnswer ? (
				<Typewriter
					options={{
						delay: 10,
					}}
					onInit={(typewriter) => {
						typewriter
							.typeString(answer.message)
							.callFunction(() => {
								typewriter.stop();
								setShouldType(false);
								if (
									currentQuestions.length > 2 &&
									(currentChat?.rate ?? -1) < 0
								) {
									setTimeout(() => {
										setShowScoreModal(true);
									}, 3000);
								}
							})
							.start();
					}}
				/>
			) : (
				<Box sx={{ position: "relative", width: "100%" }}>
					<Box sx={{ display: "flex", flexDirection: "column" }}>
						<Typography
							variant="body2"
							gutterBottom
							sx={{ lineHeight: 1.8, marginBottom: 0 }}
						>
							{answer.message}
						</Typography>
					</Box>

					{isCopied ? (
						<Check
							style={{ position: "absolute", top: -15, right: -15 }}
							className={classes.checkIcon}
						/>
					) : (
						<Clipboard
							style={{ position: "absolute", top: -15, right: -15 }}
							className={classes.clipboardIcon}
							onClick={() => {
								handleCopyToClipboard();
								setIsCopied(true);
								setTimeout(() => {
									setIsCopied(false);
								}, 800);
							}}
						/>
					)}
				</Box>
			)}
		</Box>
	);
}
