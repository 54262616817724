import { ThemeProvider } from "@mui/material/styles";
import theme from "./Theme";
import { UserProvider } from "./contexts/user";
import { MainLayout } from "./components";
import { QueryClient, QueryClientProvider } from "react-query";
import { SnackbarProvider } from "notistack";
import { MaterialDesignContent } from "notistack";
import styled from "styled-components";
import { BrowserRouter } from "react-router-dom";

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
	"&.notistack-MuiContent-success": {
		backgroundColor: "#2D7738",
		boxShadow: "none",
	},
	"&.notistack-MuiContent-error": {
		backgroundColor: "#EB4B4B",
		boxShadow: "none",
	},
}));

const queryClient = new QueryClient();

function App() {
	return (
		<ThemeProvider theme={theme}>
			<SnackbarProvider
				maxSnack={3}
				anchorOrigin={{ vertical: "top", horizontal: "right" }}
				autoHideDuration={5000}
				Components={{
					success: StyledMaterialDesignContent,
					error: StyledMaterialDesignContent,
				}}
			>
				<QueryClientProvider client={queryClient}>
					<UserProvider>
						<BrowserRouter>
							<MainLayout />
						</BrowserRouter>
					</UserProvider>
				</QueryClientProvider>
			</SnackbarProvider>
		</ThemeProvider>
	);
}

export default App;
