import { useEffect, useState } from "react";
import {
	Typography,
	Box,
	Button,
	Grid,
	CircularProgress,
	Tabs,
	Tab,
} from "@mui/material";
import { PropTypes } from "./NewChatOnboarding.types";
import { MultiItemSelector } from "../MultiItemSelector";
import { useMainStore } from "../../stores/main";
import { ISource, IChat, ITag, IQuestion } from "../../models";
import { source as sourceActions, tag as tagActions } from "../../controllers";
import { useMutation, useQuery } from "react-query";
import { SnackbarKey, useSnackbar } from "notistack";
import { SuggestedChat } from "../SuggestedChat";
import { chat as chatActions } from "../../controllers";
import { FiPlus } from "react-icons/fi";
import { ChatMessages } from "../ChatMessages";

export function NewChatOnboarding(props: PropTypes) {
	const setCurrentStoreSources = useMainStore(
		(state) => state.setCurrentSources
	);
	const [currentSources, setCurrentSources] = useState<Array<ISource>>([]);
	const [sourcesFilter, setSourcesFilter] = useState("");
	const [sources, setSources] = useState<Array<ISource>>(Array<ISource>());

	const currentTags = useMainStore((state) => state.currentTags);
	const setCurrentTags = useMainStore((state) => state.setCurrentTags);
	const [tagsFilter, setTagsFilter] = useState("");
	const [tags, setTags] = useState<Array<ITag>>(Array<ITag>());

	const chats = useMainStore((state) => state.chats);
	const addChat = useMainStore((state) => state.addChat);
	const setCurrentChat = useMainStore((state) => state.setCurrentChat);
	const [recommendations, setRecomendations] = useState<Array<IChat>>(
		Array<IChat>()
	);
	const [currentQuestions, setCurrentQuestions] = useState<Array<IQuestion>>(
		[]
	);
	const [selectedRecommendation, setSelectedRecommendation] =
		useState<IChat | null>(null);
	const [currentStep, setCurrentStep] = useState(0);
	const [tabValue, setTabValue] = useState(0);

	const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
		setTabValue(newValue);
	};

	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	function a11yProps(index: number) {
		return {
			id: `simple-tab-${index}`,
			"aria-controls": `simple-tabpanel-${index}`,
		};
	}

	const { isLoading, mutate } = useMutation(
		"chats",
		() => chatActions.create({ name: `New Chat ${chats.length + 1}` }),
		{
			retry: false,
			onSuccess: (res) => {
				console.log(res);
				setCurrentChat(res);
				addChat(res);
				setCurrentStoreSources(currentSources);
				props.handleClose();
			},
			onError: (error: any) => {
				console.log(error);
				const key: SnackbarKey = enqueueSnackbar("Something went wrong", {
					variant: "error",
					SnackbarProps: {
						onClick: () => closeSnackbar(key),
					},
				});
			},
		}
	);

	const { isLoading: isLoadingSavedChats, mutate: mutateSavedChats } =
		useMutation(
			"update-saved-chats",
			() =>
				chatActions.updateSavedChats({ chatId: selectedRecommendation!.id }),
			{
				retry: false,
				onSuccess: (res) => {
					console.log(res);
					props.handleClose(true);
					const key: SnackbarKey = enqueueSnackbar(
						"Chat has been saved to your list",
						{
							variant: "success",
							SnackbarProps: {
								onClick: () => closeSnackbar(key),
							},
						}
					);
				},
				onError: (error: any) => {
					console.log(error);
					const key: SnackbarKey = enqueueSnackbar("Something went wrong", {
						variant: "error",
						SnackbarProps: {
							onClick: () => closeSnackbar(key),
						},
					});
				},
			}
		);

	const steps = [
		{
			id: 1,
			title: "Select Sources", // or Tags",
			description:
				"Choose what sources you want your chat to use as the context",
			//"Choose what sources or tags you want your chat to use as the context",
			type: "multi_item_selector",
			button: "Next",
			callbackButton: () => {
				setCurrentStep(1);
			},
			showBack: false,
		},
		{
			id: 2,
			title: "Browse Chats",
			description:
				"Take a look at what chats other users created with the selected sources or start a new one",
			type: "browse_chats",
			showBack: true,
		},
		{
			id: 3,
			title: "Review Chat",
			description: "Check the existing content or save it for later usage",
			type: "chat",
			button: "Save",
			callbackButton: () => {
				mutateSavedChats();
			},
			showBack: true,
		},
	];

	const { refetch: refetchSources } = useQuery(
		"new-sources",
		() => sourceActions.list(sourcesFilter),
		{
			enabled: true,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				console.log(res);
				setSources(res);
			},
			onError: (error: any) => {
				console.log(error);
				const key: SnackbarKey = enqueueSnackbar("Something went wrong", {
					variant: "error",
					SnackbarProps: {
						onClick: () => closeSnackbar(key),
					},
				});
			},
		}
	);

	const { refetch: refetchTags } = useQuery(
		"new-tags",
		() => tagActions.list(),
		{
			enabled: true,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				console.log(res);
				setTags(res);
			},
			onError: (error: any) => {
				console.log(error);
				const key: SnackbarKey = enqueueSnackbar("Something went wrong", {
					variant: "error",
					SnackbarProps: {
						onClick: () => closeSnackbar(key),
					},
				});
			},
		}
	);

	const { isLoading: chatsLoading, refetch } = useQuery(
		"recommended-chats",
		() =>
			chatActions.list(
				undefined,
				currentSources.map((source) => source.id),
				false,
				"-rate"
			),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				console.log(res);
				setRecomendations(res);
			},
			onError: (error: any) => {
				console.log(error);
				const key: SnackbarKey = enqueueSnackbar("Something went wrong", {
					variant: "error",
					SnackbarProps: {
						onClick: () => closeSnackbar(key),
					},
				});
			},
		}
	);

	useEffect(() => {
		if (currentStep === 1) {
			refetch();
		}
	}, [currentSources.length, currentStep, refetch, setCurrentSources]);

	useEffect(() => {
		refetchSources();
	}, [refetchSources, sourcesFilter]);

	useEffect(() => {
		refetchTags();
	}, [refetchTags, tagsFilter]);

	return (
		<Box sx={{ width: "100%" }}>
			{steps[currentStep].showBack && (
				<Typography
					variant="body2"
					fontSize={10}
					marginBottom={2}
					sx={{
						position: "absolute",
						cursor: "pointer",
						"&:hover": { textDecoration: "underline" },
					}}
					onClick={() => {
						if (currentStep === 1) {
							setRecomendations([]);
						}
						setCurrentStep(currentStep - 1);
					}}
				>
					{"< BACK"}
				</Typography>
			)}
			<Typography
				variant="h5"
				sx={{ fontWeight: 700, textAlign: "center", marginBottom: 2 }}
			>
				{steps[currentStep].title}
			</Typography>
			<Typography
				sx={{ marginTop: 1, textAlign: "center", marginBottom: 2 }}
				variant="subtitle1"
			>
				{steps[currentStep].description}
			</Typography>
			<Box
				sx={{
					borderBottom: 1,
					borderColor: "divider",
					display: "flex",
					justifyContent: "center",
				}}
			></Box>

			{steps[currentStep].type === "multi_item_selector" && (
				<Box sx={{ maxHeight: "calc(100vh - 270px)", overflow: "auto" }}>
					<Box sx={{ display: "flex", justifyContent: "center" }}>
						<Tabs
							TabIndicatorProps={{
								style: {
									display: "none",
								},
							}}
							value={tabValue}
							onChange={handleTabChange}
							aria-label="tabs"
						>
							<Tab label="SOURCES" {...a11yProps(0)} />
							{/*<Tab label="TAGS" {...a11yProps(1)} disabled />*/}
						</Tabs>
					</Box>

					<Box
						sx={{
							borderBottom: 1,
							borderColor: "divider",
							display: "flex",
							justifyContent: "center",
							marginBottom: "40px",
						}}
					></Box>
					{tabValue === 0 && (
						<MultiItemSelector
							title="Select Sources"
							placeholderAutocomplete="Search Source"
							buttonTitle="Add source"
							listTitle="Sources Selected"
							emptyState="No sources applied yet"
							setFilter={setSourcesFilter}
							elements={sources}
							selectedElements={currentSources}
							setSelectedElements={(elements) =>
								setCurrentSources(elements as Array<ISource>)
							}
							onboarding
						/>
					)}
					{tabValue === 1 && (
						<MultiItemSelector
							title="Select Tags"
							placeholderAutocomplete="Select Tag"
							buttonTitle="Add tag"
							listTitle="Tags Selected"
							emptyState="No tags applied yet"
							setFilter={setTagsFilter}
							elements={tags}
							selectedElements={currentTags}
							setSelectedElements={(elements) =>
								setCurrentTags(elements as Array<ITag>)
							}
							onboarding
						/>
					)}
				</Box>
			)}

			{steps[currentStep].type === "browse_chats" && (
				<>
					{chatsLoading ? (
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								padding: 4,
							}}
						>
							<CircularProgress size={24} />
						</Box>
					) : (
						<>
							{recommendations.length > 0 && (
								<Grid
									container
									spacing={2}
									padding={2}
									sx={{
										maxHeight: "calc(100vh - 310px)",
										overflow: "auto",
										marginTop: 0,
										paddingTop: 0,
									}}
								>
									{recommendations.map((recommendation, index) => (
										<Grid key={index} item xs={12} sm={6} md={6}>
											<SuggestedChat
												title={recommendation.name}
												callback={() => {
													setCurrentStep(2);
													setSelectedRecommendation(recommendation);
												}}
												user={recommendation.user.email}
												rate={recommendation.rate}
											/>
										</Grid>
									))}
								</Grid>
							)}

							<Typography
								sx={{
									textAlign: "center",
									marginBottom: recommendations.length > 0 ? 2 : 4,
									marginTop: recommendations.length > 0 ? 1 : 4,
								}}
								variant="body2"
							>
								{recommendations.length > 0
									? "or"
									: "Nobody has a chat with those sources or tags. Start a new one!"}
							</Typography>
							<Box sx={{ paddingLeft: 2, paddingRight: 2 }}>
								<Button
									fullWidth
									variant="contained"
									onClick={() => {
										mutate();
									}}
								>
									{isLoading ? (
										<CircularProgress size={12} />
									) : (
										<>
											<FiPlus style={{ marginRight: 6, fontSize: 12 }} />
											New Chat
										</>
									)}
								</Button>
							</Box>
						</>
					)}
				</>
			)}

			{steps[currentStep].type === "chat" && (
				<Box sx={{ maxHeight: "calc(100vh - 270px)", overflow: "auto" }}>
					<ChatMessages
						chat={selectedRecommendation!}
						currentQuestions={currentQuestions}
						setCurrentQuestions={setCurrentQuestions}
						inOnboarding
					/>
				</Box>
			)}

			{steps[currentStep].button && (
				<Box display="flex" justifyContent="flex-end" sx={{ marginTop: 2 }}>
					<Button
						sx={{ minHeight: 34 }}
						variant="contained"
						onClick={() => {
							steps[currentStep].callbackButton!();
						}}
					>
						{isLoadingSavedChats ? (
							<CircularProgress sx={{ color: "white" }} size={12} />
						) : (
							steps[currentStep].button
						)}
					</Button>
				</Box>
			)}
		</Box>
	);
}
