import {
	TextField,
	Button,
	FormControl,
	Autocomplete,
	CircularProgress,
	MenuItem,
	Box,
	Typography,
	Paper,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useQuery, useMutation } from "react-query";
import { SnackbarKey, useSnackbar } from "notistack";
import { source as sourcesActions, tag as tagActions } from "../../controllers";
import { useCallback, useEffect, useState } from "react";
import { ITag } from "../../models/tag-model";
import { ISourceType } from "../../models";
import { PropTypes } from "./UplaodSource.types";
import { HiOutlineUpload as Upload } from "react-icons/hi";
import { useRef } from "react";
import { HiOutlineTrash as Trash } from "react-icons/hi2";

const useStyles = makeStyles({
	inputLabel: {
		textTransform: "uppercase",
		fontSize: 10,
		marginTop: 16,
		marginBottom: 4,
		fontWeight: 700,
		color: "#686868",
	},
});

export function UploadSourceForm(props: PropTypes) {
	const classes = useStyles();
	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [originalUrl, setOriginalUrl] = useState("");
	const [sourceType, setSourceType] = useState<ISourceType>("PDF");
	const [originalTags, setOriginalTags] = useState<Array<ITag>>([]);
	const [tags, setTags] = useState<Array<ITag>>([]);
	const fileInputRef = useRef<HTMLInputElement | null>(null);
	const [file, setFile] = useState<File | null>(null);
	const [sourceId, setSourceId] = useState<number | null>(null);
	const [sourceLoading, setSourceLoading] = useState<boolean>(false);
	const [retries, setRetries] = useState<number>(0);

	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	useQuery("tags", () => tagActions.list(), {
		enabled: true,
		refetchOnWindowFocus: false,
		retry: false,
		onSuccess: (res) => {
			console.log(res);
			setOriginalTags(res);
		},
		onError: (error: any) => {
			console.log(error);
			const key: SnackbarKey = enqueueSnackbar("Something went wrong", {
				variant: "error",
				SnackbarProps: {
					onClick: () => closeSnackbar(key),
				},
			});
		},
	});

	const { refetch } = useQuery(
		"source",
		() => sourcesActions.getOne(sourceId!),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				if (res.url) {
					const key: SnackbarKey = enqueueSnackbar(
						"Source successfully created",
						{
							variant: "success",
							SnackbarProps: {
								onClick: () => closeSnackbar(key),
							},
						}
					);
					if (props.successCallback) {
						props.successCallback();
					}
					setSourceLoading(false);
				} else if (retries < 6) {
					setRetries(retries + 1);
					setTimeout(() => {
						refetch();
					}, 20000);
				} else {
					const key: SnackbarKey = enqueueSnackbar(
						"Something went wrong and the source couldn't be processed. Please, inform the tech team about it.",
						{
							variant: "error",
							SnackbarProps: {
								onClick: () => closeSnackbar(key),
							},
						}
					);
					setSourceLoading(false);
				}
			},
			onError: (error: any) => {
				console.log(error);
				const key: SnackbarKey = enqueueSnackbar(
					"Something went wrong and the source couldn't be processed. Please, inform the tech team about it.",
					{
						variant: "error",
						SnackbarProps: {
							onClick: () => closeSnackbar(key),
						},
					}
				);
				setSourceLoading(false);
			},
		}
	);

	const createSource = useCallback(() => {
		return sourcesActions.create({
			name: name.replaceAll(" ", "_"),
			description,
			url: "",
			original_url: originalUrl,
			file: file,
			source_type: sourceType,
			tags: tags.map((tag) => tag.id),
		});
	}, [name, description, originalUrl, file, sourceType, tags]);

	const { mutate, isLoading } = useMutation("sources", () => createSource(), {
		retry: true,
		onSuccess: (res) => {
			setTimeout(() => {
				setSourceId(res.id);
			}, 20000);
		},
		onError: (error: any) => {
			console.log(error);
			const key: SnackbarKey = enqueueSnackbar("Something went wrong", {
				variant: "error",
				SnackbarProps: {
					onClick: () => closeSnackbar(key),
				},
			});
			setSourceLoading(false);
		},
	});

	useEffect(() => {
		if (sourceId) {
			refetch();
		}
	}, [sourceId, refetch]);

	const onSubmit = () => {
		setRetries(0);
		setSourceLoading(true);
		mutate();
	};

	const handleFileChange = (files: FileList | null) => {
		if (files) {
			if (files.length > 0) {
				validateFile(files[0]);
			}
		}
	};

	const handleDragOver = (e: React.DragEvent) => {
		e.preventDefault();
	};

	const handleDrop = (e: React.DragEvent) => {
		e.preventDefault();
		const droppedFiles = e.dataTransfer.files;

		if (droppedFiles.length > 0) {
			validateFile(droppedFiles[0]);
		}
	};

	const validateFile = (file: File) => {
		if (file.size < 5242880) {
			setFile(file);

			console.log("Selected file:", file.name);
		} else {
			const key: SnackbarKey = enqueueSnackbar(
				"File size is too big. Maximum allowed size is 5Mb.",
				{
					variant: "error",
					SnackbarProps: {
						onClick: () => closeSnackbar(key),
					},
				}
			);
		}
	};

	return (
		<FormControl
			sx={{ width: "100%" }}
			onDragOver={handleDragOver}
			onDrop={handleDrop}
		>
			<span className={classes.inputLabel}>Name</span>
			<TextField
				InputLabelProps={{ shrink: false }}
				fullWidth
				label=""
				id="name"
				placeholder="Name of the source"
				value={name}
				onChange={(e) => {
					setName(e.target.value);
				}}
			/>
			<span className={classes.inputLabel}>Description</span>
			<TextField
				InputLabelProps={{ shrink: false }}
				fullWidth
				label=""
				multiline
				rows={3}
				id="description"
				placeholder="Description of the source"
				value={description}
				onChange={(e) => {
					setDescription(e.target.value);
				}}
			/>
			<span className={classes.inputLabel}>Source Type</span>
			<TextField
				InputLabelProps={{ shrink: false }}
				fullWidth
				label=""
				id="source_type"
				placeholder="Source Type"
				select
				value={sourceType}
				onChange={(e) => {
					setSourceType(e.target.value as ISourceType);
				}}
			>
				{["PDF", "WEB"].map((option) => (
					<MenuItem key={option} value={option}>
						{option}
					</MenuItem>
				))}
			</TextField>
			{sourceType !== "WEB" && (
				<>
					<span className={classes.inputLabel}>Source</span>
					<Box
						sx={{
							backgroundColor: "#fafafa",
							padding: 4,
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							flexDirection: "column",
						}}
					>
						{file ? (
							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
								}}
							>
								<Typography
									variant="body2"
									sx={{
										fontSize: "14px",
										textAlign: "center",
										maxWidth: "300px",
										display: "-webkit-box",
										WebkitBoxOrient: "vertical",
										WebkitLineClamp: 3,
										overflow: "hidden",
										textOverflow: "ellipsis",
										flex: 1,
									}}
								>
									{file.name}
								</Typography>
								<Trash
									onClick={() => {
										setFile(null);
									}}
									style={{ marginLeft: "12px", cursor: "pointer" }}
								/>
							</Box>
						) : (
							<>
								<Upload size={20} style={{ marginBottom: "12px" }} />
								<Typography
									variant="body2"
									gutterBottom
									sx={{
										fontSize: "14px",
										textAlign: "center",
									}}
								>
									Drag and drop the file here
								</Typography>
								<Typography
									variant="body2"
									gutterBottom
									sx={{
										fontSize: "14px",
										textAlign: "center",
									}}
								>
									or
								</Typography>
								<Button
									sx={{
										padding: 0,
									}}
									onClick={() => fileInputRef.current?.click()}
								>
									Upload file
								</Button>
								<input
									type="file"
									id="fileInput"
									accept=".pdf"
									style={{ display: "none" }}
									onChange={(e) => handleFileChange(e.target.files)}
									ref={fileInputRef}
								/>
							</>
						)}
					</Box>
					{(file?.size ?? 0) > 1048576 && (
						<Typography
							variant="body2"
							sx={{ color: "#9a4a4a", fontSize: 12, marginTop: "5px" }}
						>
							Since you are uploading a big file, have in mind it could take up
							to 2 minutes.
						</Typography>
					)}
				</>
			)}
			<span className={classes.inputLabel}>URL</span>
			<TextField
				InputLabelProps={{ shrink: false }}
				fullWidth
				label=""
				id="url"
				placeholder="URL"
				value={originalUrl}
				onChange={(e) => {
					setOriginalUrl(e.target.value);
				}}
			/>
			<span className={classes.inputLabel}>Tags</span>
			<Autocomplete
				fullWidth
				disablePortal
				id="tags"
				options={originalTags}
				getOptionLabel={(option) => option.name}
				multiple
				value={tags}
				renderInput={(params) => (
					<TextField {...params} label="" placeholder="Select tags" />
				)}
				onChange={(_, value) => {
					setTags([...value]);
				}}
				PaperComponent={({ children }) => (
					<Paper elevation={0} style={{ marginTop: -170 }}>
						{children}
					</Paper>
				)}
			/>
			<Button
				variant="contained"
				sx={{
					marginTop: 2,
					maxWidth: 300,
					alignSelf: "end",
					minHeight: 34,
					minWidth: 135,
				}}
				disabled={
					isLoading ||
					sourceLoading ||
					!name ||
					!description ||
					(!originalUrl && !file)
				}
				onClick={onSubmit}
			>
				{sourceLoading ? (
					<CircularProgress size={24} sx={{ color: "white" }} />
				) : (
					"Upload source"
				)}
			</Button>
		</FormControl>
	);
}
